export const fruits = {
  "🍇": {
    points: 200,
    rate: 1 / 200,
    name: "Grape",
  },
  "🍈": {
    points: 300,
    rate: 1 / 300,
    name: "Melon",
  },
  "🍉": {
    points: 400,
    rate: 1 / 400,
    name: "Watermelon",
  },
  "🍊": {
    points: 500,
    rate: 1 / 500,
    name: "Orange",
  },
  "🍋": {
    points: 600,
    rate: 1 / 600,
    name: "Lemon",
  },
  "🍌": {
    points: 700,
    rate: 1 / 700,
    name: "Banana",
  },
  "🍍": {
    points: 800,
    rate: 1 / 800,
    name: "Pineapple",
  },
  "🥭": {
    points: 900,
    rate: 1 / 900,
    name: "Mango",
  },
  "🍎": {
    points: 1000,
    rate: 1 / 1000,
    name: "Apple",
  },
  "🍏": {
    points: 1100,
    rate: 1 / 1100,
    name: "Green Apple",
  },
  "🍐": {
    points: 1200,
    rate: 1 / 1200,
    name: "Pear",
  },
  "🍑": {
    points: 1300,
    rate: 1 / 1300,
    name: "Peach",
  },
  "🍒": {
    points: 1400,
    rate: 1 / 1400,
    name: "Cherry",
  },
  "🍓": {
    points: 1500,
    rate: 1 / 1500,
    name: "Strawberry",
  },
  "🥝": {
    points: 1600,
    rate: 1 / 1600,
    name: "Kiwi",
  },
  "🍅": {
    points: 1700,
    rate: 1 / 1700,
    name: "Tomato",
  },
  "🥥": {
    points: 1800,
    rate: 1 / 1800,
    name: "Coconut",
  },
};

export type Fruit = keyof typeof fruits;
export const fruitList = Object.keys(fruits) as Fruit[];

export const vegetables = {
  "🥕": {
    points: 400,
    rate: 1 / 400,
    name: "Carrot",
  },
  "🥔": {
    points: 500,
    rate: 1 / 500,
    name: "Potato",
  },
  "🌽": {
    points: 600,
    rate: 1 / 600,
    name: "Corn",
  },
  "🍠": {
    points: 700,
    rate: 1 / 700,
    name: "Sweet Potato",
  },
  "🥦": {
    points: 800,
    rate: 1 / 800,
    name: "Broccoli",
  },
  "🥒": {
    points: 900,
    rate: 1 / 900,
    name: "Cucumber",
  },
  "🥬": {
    points: 1000,
    rate: 1 / 1000,
    name: "Leafy Green",
  },
  "🌶️": {
    points: 1100,
    rate: 1 / 1100,
    name: "Chili Pepper",
  },
  "🥑": {
    points: 1200,
    rate: 1 / 1200,
    name: "Avocado",
  },
  "🍆": {
    points: 1300,
    rate: 1 / 1300,
    name: "Eggplant",
  },
  "🥝": {
    points: 1400,
    rate: 1 / 1400,
    name: "Kiwi",
  },
  "🌰": {
    points: 1500,
    rate: 1 / 1500,
    name: "Chestnut",
  },
  "🧅": {
    points: 1600,
    rate: 1 / 1600,
    name: "Onion",
  },
  "🥜": {
    points: 1700,
    rate: 1 / 1700,
    name: "Peanut",
  },
};

export type Vegetable = keyof typeof vegetables;
export const vegetableList = Object.keys(vegetables) as Vegetable[];

export const preparedFoods = {
  "🍞": {
    points: 3000,
    rate: 1 / 3000,
    name: "Bread",
  },
  "🥐": {
    points: 3100,
    rate: 1 / 3100,
    name: "Croissant",
  },
  "🥖": {
    points: 3200,
    rate: 1 / 3200,
    name: "Baguette",
  },
  "🥨": {
    points: 3300,
    rate: 1 / 3300,
    name: "Pretzel",
  },
  "🥯": {
    points: 3400,
    rate: 1 / 3400,
    name: "Bagel",
  },
  "🥞": {
    points: 3500,
    rate: 1 / 3500,
    name: "Pancakes",
  },
  "🧇": {
    points: 3600,
    rate: 1 / 3600,
    name: "Waffle",
  },
  "🧀": {
    points: 3700,
    rate: 1 / 3700,
    name: "Cheese",
  },
  "🍖": {
    points: 3800,
    rate: 1 / 3800,
    name: "Ham",
  },
  "🍗": {
    points: 3900,
    rate: 1 / 3900,
    name: "Chicken",
  },
  "🥩": {
    points: 4000,
    rate: 1 / 4000,
    name: "Steak",
  },
  "🥓": {
    points: 4100,
    rate: 1 / 4100,
    name: "Bacon",
  },
  "🍔": {
    points: 4200,
    rate: 1 / 4200,
    name: "Hamburger",
  },
  "🍟": {
    points: 4300,
    rate: 1 / 4300,
    name: "French Fries",
  },
  "🍕": {
    points: 4400,
    rate: 1 / 4400,
    name: "Pizza",
  },
  "🌭": {
    points: 4500,
    rate: 1 / 4500,
    name: "Hot Dog",
  },
  "🥪": {
    points: 4600,
    rate: 1 / 4600,
    name: "Sandwich",
  },
  "🌮": {
    points: 4700,
    rate: 1 / 4700,
    name: "Taco",
  },
  "🌯": {
    points: 4800,
    rate: 1 / 4800,
    name: "Burrito",
  },
  "🥙": {
    points: 4900,
    rate: 1 / 4900,
    name: "Stuffed Flatbread",
  },
  "🧆": {
    points: 5000,
    rate: 1 / 5000,
    name: "Falafel",
  },
  "🥚": {
    points: 5100,
    rate: 1 / 5100,
    name: "Egg",
  },
  "🥘": {
    points: 5200,
    rate: 1 / 5200,
    name: "Shallow Pan of Food",
  },
  "🍲": {
    points: 5300,
    rate: 1 / 5300,
    name: "Pot of Food",
  },
  "🥣": {
    points: 5400,
    rate: 1 / 5400,
    name: "Bowl with Spoon",
  },
  "🥗": {
    points: 5500,
    rate: 1 / 5500,
    name: "Green Salad",
  },
  "🍿": {
    points: 5600,
    rate: 1 / 5600,
    name: "Popcorn",
  },
  "🧈": {
    points: 5700,
    rate: 1 / 5700,
    name: "Butter",
  },
  "🧂": {
    points: 5800,
    rate: 1 / 5800,
    name: "Salt",
  },
  "🥫": {
    points: 5900,
    rate: 1 / 5900,
    name: "Canned Food",
  },
  "🍝": {
    points: 6000,
    rate: 1 / 6000,
    name: "Spaghetti",
  },
  "🍱": {
    points: 6100,
    rate: 1 / 6100,
    name: "Bento Box",
  },
  "🍘": {
    points: 6200,
    rate: 1 / 6200,
    name: "Rice Cracker",
  },
  "🍙": {
    points: 6300,
    rate: 1 / 6300,
    name: "Rice Ball",
  },
  "🍚": {
    points: 6400,
    rate: 1 / 6400,
    name: "Cooked Rice",
  },
  "🍛": {
    points: 6500,
    rate: 1 / 6500,
    name: "Curry Rice",
  },
  "🍜": {
    points: 6600,
    rate: 1 / 6600,
    name: "Steaming Bowl",
  },
  "🍠": {
    points: 6700,
    rate: 1 / 6700,
    name: "Roasted Sweet Potato",
  },
  "🍢": {
    points: 6800,
    rate: 1 / 6800,
    name: "Oden",
  },
  "🍣": {
    points: 6900,
    rate: 1 / 6900,
    name: "Sushi",
  },
  "🍤": {
    points: 7000,
    rate: 1 / 7000,
    name: "Fried Shrimp",
  },
  "🍥": {
    points: 7100,
    rate: 1 / 7100,
    name: "Fish Cake with Swirl",
  },
  "🥮": {
    points: 7200,
    rate: 1 / 7200,
    name: "Moon Cake",
  },
  "🍡": {
    points: 7300,
    rate: 1 / 7300,
    name: "Dango",
  },
  "🥟": {
    points: 7400,
    rate: 1 / 7400,
    name: "Dumpling",
  },
  "🥠": {
    points: 7500,
    rate: 1 / 7500,
    name: "Fortune Cookie",
  },
  "🥡": {
    points: 7600,
    rate: 1 / 7600,
    name: "Takeout Box",
  },
};

export type PreparedFood = keyof typeof preparedFoods;
export const preparedFoodList = Object.keys(preparedFoods) as PreparedFood[];

export const deserts = {
  "🍦": {
    points: 1500,
    rate: 1 / 1500,
    name: "Soft Ice Cream",
  },
  "🍧": {
    points: 1600,
    rate: 1 / 1600,
    name: "Shaved Ice",
  },
  "🍨": {
    points: 1700,
    rate: 1 / 1700,
    name: "Ice Cream",
  },
  "🍩": {
    points: 1800,
    rate: 1 / 1800,
    name: "Doughnut",
  },
  "🍪": {
    points: 1900,
    rate: 1 / 1900,
    name: "Cookie",
  },
  "🎂": {
    points: 2000,
    rate: 1 / 2000,
    name: "Birthday Cake",
  },
  "🍰": {
    points: 2100,
    rate: 1 / 2100,
    name: "Shortcake",
  },
  "🧁": {
    points: 2200,
    rate: 1 / 2200,
    name: "Cupcake",
  },
  "🥧": {
    points: 2300,
    rate: 1 / 2300,
    name: "Pie",
  },
  "🍫": {
    points: 2400,
    rate: 1 / 2400,
    name: "Chocolate Bar",
  },
  "🍬": {
    points: 2500,
    rate: 1 / 2500,
    name: "Candy",
  },
  "🍭": {
    points: 2600,
    rate: 1 / 2600,
    name: "Lollipop",
  },
  "🍮": {
    points: 2700,
    rate: 1 / 2700,
    name: "Custard",
  },
  "🍯": {
    points: 2800,
    rate: 1 / 2800,
    name: "Honey Pot",
  },
};

export const drinks = {
  "🥤": {
    points: 1000,
    rate: 1 / 1000,
    name: "Cup With Straw",
  },
  "🧃": {
    points: 1100,
    rate: 1 / 1100,
    name: "Beverage Box",
  },
  "🧉": {
    points: 1200,
    rate: 1 / 1200,
    name: "Mate",
  },
  "🍼": {
    points: 1300,
    rate: 1 / 1300,
    name: "Bottle",
  },
  "🥛": {
    points: 1400,
    rate: 1 / 1400,
    name: "Glass of Milk",
  },
  "☕": {
    points: 1500,
    rate: 1 / 1500,
    name: "Coffee",
  },
  "🍵": {
    points: 1600,
    rate: 1 / 1600,
    name: "Teacup",
  },
  "🍶": {
    points: 1700,
    rate: 1 / 1700,
    name: "Sake",
  },
  "🍾": {
    points: 1800,
    rate: 1 / 1800,
    name: "Bottle with Popping Cork",
  },
  "🍷": {
    points: 1900,
    rate: 1 / 1900,
    name: "Wine",
  },
  "🍸": {
    points: 2000,
    rate: 1 / 2000,
    name: "Cocktail",
  },
  "🍹": {
    points: 2100,
    rate: 1 / 2100,
    name: "Margherita",
  },
  "🍺": {
    points: 2200,
    rate: 1 / 2200,
    name: "Beer",
  },
  "🥃": {
    points: 2500,
    rate: 1 / 2500,
    name: "Whiskey",
  },
};

export type Drink = keyof typeof drinks;
export const drinkList = Object.keys(drinks) as Drink[];

export const foods = {
  ...fruits,
  ...vegetables,
  ...preparedFoods,
  ...deserts,
  ...drinks,
};
