import { Signal, signal } from "@preact/signals";
import { ObjectWithRate } from "~/games/eikoo/types.ts";
import { foods } from "~/games/eikoo/data/clickables.ts";

export const isPaused = signal(false);
export const isGameOver = signal(false);

export const points = signal(0);
export const possibilities = signal<ObjectWithRate[]>([]);

export const statsSignals = Object.entries(foods).reduce(
  (acc, [key]) => {
    acc[key as keyof typeof foods] = {
      collected: signal(0),
      missed: signal(0),
    };
    return acc;
  },
  {} as Record<
    keyof typeof foods,
    { collected: Signal<number>; missed: Signal<number> }
  >,
);

export const lastEvents = signal<string[]>([]);
