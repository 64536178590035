import { Sprite } from "../../deps.ts";
export const suits = ["spade", "heart", "diamond", "club"];
export const faces = [
    "ace",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "jack",
    "queen",
    "king",
];
export type Suit = typeof suits[number];
export type Face = typeof faces[number];
export const backs = ["green", "blue", "red", "black"];
export type Back = typeof backs[number];
export class Card extends Sprite {
    suit: Suit;
    face: Face;
    back: Back;
    value: number;

    constructor(
        scene: Phaser.Scene,
        x: number,
        y: number,
        suit: Suit | number,
        face: Face | number,
        back: Back | number = 1,
    ) {
        suit = typeof suit == "number" ? suits[suit - 1] : suit;
        face = typeof face == "number" ? faces[face - 1] : face;
        back = typeof back == "number" ? backs[back - 1] : back;
        const value = faces.indexOf(face) + 1;

        super(scene, x, y, `${suit}-${face}`);
        this.suit = suit;
        this.face = face;
        this.back = back;
        this.value = value;
    }
}
