import { AUTO, Scale } from "../deps.ts";

export const config = {
  width: 1280,
  height: 720,

  type: AUTO,
  backgroundColor: "#242424",
  fps: {
    target: 45,
    forceSetTimeOut: true,
  },
  scale: {
    mode: Scale.RESIZE,
    autoCenter: Scale.CENTER_BOTH,
  },
  physics: {
    default: "arcade",
    arcade: {
      gravity: { y: 0, x: 0 },
      // debug: true,
      // debugShowBody: true,
      // debugShowStaticBody: true,
    },
  },
  // pixelArt: true,
  disableContextMenu: true,
  plugins: {
    scene: [],
  },
};
