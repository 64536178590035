import { BaseGame } from "../deps.ts";
import { config } from "./config.ts";
import BootScene from "./scenes/Boot.ts";

import { ui } from "~/games/signals.ts";
import GameScene from "./scenes/GameScene.ts";

export default class Game extends BaseGame {
    constructor(props: { id: string; baseURL?: string }) {
        super({
            ...props,
            bootScene: BootScene,
            ui: ui,
            config: config,
            scenes: {
                game: GameScene,
            },
        });
    }
}
