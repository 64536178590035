import { Signal, useSignal } from "@preact/signals";
import { useEffect, useRef } from "preact/hooks";
import EikooGame from "../Game.ts";
import { Button } from "~/components/Button.tsx";
import { isGameOver, isPaused } from "~/games/eikoo/signals.ts";

export default function GameMenu({
  game,
}: {
  game: Signal<EikooGame>;
}) {
  const modalRef = useRef<HTMLDialogElement>(null);
  const open = useSignal(false);

  useEffect(() => {
    if (!game.value) return;

    if (!isPaused.value) {
      open.value = false;
      modalRef.current?.close();
      return;
    }

    open.value = true;
    modalRef.current?.showModal();
  }, [isPaused.value]);

  return (
    <dialog
      ref={modalRef}
      class="bg-transparent pointer-events-auto"
      onClick={(e) => {
        e.preventDefault();
        e.stopImmediatePropagation();

        if (e.target === modalRef.current) {
          open.value = false;
          modalRef.current?.close();
          game.value.resume();
        }

        return false;
      }}
    >
      <form
        method="dialog"
        class="flex flex-col gap-4 p-8 rounded-md bg-slate-600 bg-opacity-50"
      >
        {isGameOver.value
          ? (
            <Button
              onClick={() => {
                modalRef.current?.close();
                open.value = false;
                isPaused.value = false;
                game.value.scene.run("game");
              }}
            >
              Restart
            </Button>
          )
          : (
            <Button
              onClick={() => {
                modalRef.current?.close();
                open.value = false;
                game.value.resume();
              }}
            >
              Resume
            </Button>
          )}
        <Button onClick={() => game.value.quit()}>
          Quit
        </Button>
      </form>
    </dialog>
  );
}
