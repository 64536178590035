// You can write more code here
import { BaseGame, Scene } from "~/games/deps.ts";
import { ui } from "~/games/signals.ts";
import CountdownTimer from "~/games/eikoo/components/CountdownTimer.tsx";
import Hud from "~/games/components/hud/Hud.tsx";
import Character from "../components/characters/Character.ts";
import { Signal } from "@preact/signals";

/* START OF COMPILED CODE */

export default class MobileDemo extends Scene {
  constructor() {
    super("game");

    /* START-USER-CTR-CODE */
    // Write your code here.
    /* END-USER-CTR-CODE */
  }

  editorCreate(): void {
    // tilemap
    const tilemap = this.add.tilemap("tilemap");
    tilemap.addTilesetImage("Modern_Int_A2", "Modern_Int_A2");
    tilemap.addTilesetImage("Modern_Outside_A5", "Modern_Outside_A5");
    tilemap.addTilesetImage("non-rm-a2-square", "non-rm-a2-square");
    tilemap.addTilesetImage("!doors", "!doors");
    tilemap.addTilesetImage("Modern_Int_B_Sheet", "Modern_Int_B_Sheet");
    tilemap.addTilesetImage("Gems", "items-gems");
    tilemap.addTilesetImage("Keys", "items-keys");
    tilemap.addTilesetImage("Chests", "items-chests");

    // ground
    const ground = tilemap.createLayer(
      "Ground",
      ["Modern_Int_A2", "!doors", "non-rm-a2-square"],
      0,
      0,
    )!;

    // walls
    const walls = tilemap.createLayer(
      "Walls",
      ["Modern_Outside_A5", "!doors"],
      0,
      0,
    )!;

    // windows
    const windows = tilemap.createLayer(
      "Windows",
      ["Modern_Int_B_Sheet"],
      0,
      0,
    )!;

    // doors
    const doors = tilemap.createLayer("Doors", ["!doors"], 0, 0)!;

    const items = tilemap.createFromObjects("Items", {});
    this.items = items as Phaser.GameObjects.Sprite[];
    items.forEach((item) => {
      this.physics.world.enable(item);
    });

    // player
    const player = new Character(this, 653, 175);
    this.add.existing(player);

    // enemy
    const enemy = new Character(this, 453, 175, "!Character04");
    this.add.existing(enemy);

    // roof
    const roof = tilemap.createLayer("Roof", ["non-rm-a2-square"], 0, 0)!;

    this.ground = ground;
    this.walls = walls;
    this.windows = windows;
    this.doors = doors;
    this.player = player;
    this.enemy = enemy;
    this.roof = roof;
    this.tilemap = tilemap;

    this.events.emit("scene-awake");
  }

  private ground!: Phaser.Tilemaps.TilemapLayer;
  private walls!: Phaser.Tilemaps.TilemapLayer;
  private windows!: Phaser.Tilemaps.TilemapLayer;
  private doors!: Phaser.Tilemaps.TilemapLayer;
  private player!: Character;
  private roof!: Phaser.Tilemaps.TilemapLayer;
  private tilemap!: Phaser.Tilemaps.Tilemap;
  private items!: Phaser.GameObjects.Sprite[];

  /* START-USER-CODE */

  private enemy!: Character;

  // Write your code here
  private joyStick: any;
  private cursors!: Phaser.Types.Input.Keyboard.CursorKeys;

  private life!: Signal<number>;
  private mana!: Signal<number>;

  private playerLight!: Phaser.GameObjects.Light;
  bgSound!: string;

  private runButtonDown!: boolean;
  private runButton!: Phaser.GameObjects.Shape;

  create() {
    this.editorCreate();

    const bgSound = this.sound.get("crossroads-bg");
    this.bgSound = bgSound.key;

    const crossFadeBgMusic = (newBg: string) => {
      this.sound.play(newBg, { loop: true, volume: 0 });
      const newBgSound = this.sound.get(newBg);
      this.tweens.add({
        targets: newBgSound,
        volume: 1,
        duration: 1000,
      });

      this.tweens.add({
        targets: this.sound.get(this.bgSound),
        volume: 0,
        duration: 1000,
        onComplete: () => {
          bgSound.stop();
        },
      });

      this.bgSound = newBg;
    };

    const life = new Signal(100);
    const mana = new Signal(100);

    this.life = life;
    this.mana = mana;
    this.gameOver = false;

    ui.value = [
      (props: { game: Signal<BaseGame> }) =>
        CountdownTimer({
          ...props,
          duration: bgSound.duration * 1000,
          onEnd: () => {
            // change song

            crossFadeBgMusic("Aaa1");

            ui.value = [
              (props: { game: Signal<BaseGame> }) =>
                CountdownTimer({
                  ...props,
                  duration: this.sound.get(this.bgSound).duration * 1000,
                  onEnd: () => {
                    // change song
                    crossFadeBgMusic(
                      this.bgSound === "Aaa1" ? "crossroads-bg" : "Aaa1",
                    );
                  },
                }),
              (props: { game: Signal<BaseGame> }) =>
                Hud({
                  ...props,
                  life,
                  mana,
                }),
            ];
          },
        }),
      (props: { game: Signal<BaseGame> }) =>
        Hud({
          ...props,
          life,
          mana,
        }),
    ];

    this.cameras.main.startFollow(this.player);
    this.player.setData("boostDelay", 1000);
    this.player.setData("lastBoost", 0);
    this.player.setData("boostDuration", 1000);
    this.player.setData("boostCost", 10);
    this.player.setData("boostChannellingCost", 2);

    this.input.addPointer(1);

    const joyStick = (this.plugins.get("rexvirtualjoystickplugin") as any).add(
      this,
      {
        x: 75,
        y: this.cameras.main.height - 55,
        radius: 100,
        base: this.add.circle(0, 0, 50, 0x888888, .4),
        thumb: this.add.circle(0, 0, 25, 0xcccccc, .4),
        dir: "8dir", // 'up&down'|0|'left&right'|1|'4dir'|2|'8dir'|3
        forceMin: 8,
        enable: true,
      },
    );

    this.wasd = this.input.keyboard?.addKeys({
      up: Phaser.Input.Keyboard.KeyCodes.W,
      down: Phaser.Input.Keyboard.KeyCodes.S,
      left: Phaser.Input.Keyboard.KeyCodes.A,
      right: Phaser.Input.Keyboard.KeyCodes.D,
      run: Phaser.Input.Keyboard.KeyCodes.SHIFT,
    }) as typeof this.wasd;

    // if any key is pressed, disable joystick
    this.input.keyboard?.on("keydown", () => {
      joyStick.setEnable(false);
      // hide joystick
      joyStick.setVisible(false);
    });

    // if any touch (only touch, not mouse) is detected, enable joystick
    this.input.on("pointerdown", (pointer: Phaser.Input.Pointer) => {
      if (pointer.wasTouch) {
        joyStick.setEnable(true);
        // show joystick
        joyStick.setVisible(true);
      }
    });

    this.cursors = joyStick.createCursorKeys();
    this.joyStick = joyStick;

    const setBoostButton = (value: boolean) => {
      this.runButtonDown = value;

      if (value) {
        this.player.setData("boostStart", this.game.getTime());
      } else {
        this.player.setData("boostStart", 0);
      }
    };

    this.runButtonDown = false;
    const runButton = this.add.circle(
      this.cameras.main.width - 35,
      this.cameras.main.height - 35,
      50,
    ).setStrokeStyle(2, 0xff0000)
      .setInteractive()
      .on("pointerdown", () => {
        setBoostButton(true);
      })
      .on("pointerup", () => {
        setBoostButton(false);
      })
      .on("pointerout", () => {
        setBoostButton(false);
      });
    // don't move with camera
    runButton.setScrollFactor(0);
    this.runButton = runButton;

    // add keyboard event for run button
    this.input.keyboard?.on("keydown", (event: KeyboardEvent) => {
      if (event.key === "Shift") {
        setBoostButton(true);
      }
    });

    this.input.keyboard?.on("keyup", (event: KeyboardEvent) => {
      if (event.key === "Shift") {
        setBoostButton(false);
      }
    });

    this.walls.setCollisionByExclusion([-1]);

    // Set the player to collide with the tilemap walls
    this.physics.add.collider(this.player, this.walls);
    this.enemy.setPipeline("Light2D");
    this.physics.add.collider(this.enemy, this.walls);

    this.physics.world.setBounds(
      0,
      36,
      this.tilemap.widthInPixels,
      this.tilemap.heightInPixels - 36,
    );
    (this.player.body! as Phaser.Physics.Arcade.Body).setCollideWorldBounds(
      true,
    );
    this.enemy.body!.setCollideWorldBounds(true);
    this.enemy.setData("attackDelay", 1000);
    this.enemy.setData("lastHit", 0);

    this.physics.add.overlap(this.player, this.enemy, () => {
      if (!this.player.active) return;

      const delay = this.enemy.data.values.attackDelay;
      this.enemy.setVelocity(0, 0);
      this.enemy.setAcceleration(0, 0);

      if (this.enemy.data.values.lastHit + delay > this.game.getTime()) return;

      const damage = Math.floor(Math.random() * 10 + 5);
      this.life.value -= damage;
      this.enemy.data.values.lastHit = this.game.getTime();
      const direction = this.enemy.anims.currentAnim?.key.split("-")[1];
      this.enemy.play(`sword-${direction}`, true);
      this.enemy.setData("isAttacking", true);

      let playerId = localStorage.getItem("playerId");
      if (!playerId) {
        playerId = Math.random().toString(36).slice(2);
        localStorage.setItem("playerId", playerId);
      }

      const game = this.game as BaseGame;
      if (game.socket) {
        game.socket.send({
          type: "attack",
          source: "enemy",
          target: playerId,
          damage,
          weapon: this.enemy.data.get("weapon"),
          killingBlow: this.life.value <= 0,
        });
      }

      this.enemy.once("animationcomplete", () => {
        this.enemy.play(`idle-${direction}`, true);
        this.enemy.setData("isAttacking", false);
      });
    });
    this.physics.add.overlap(this.player, this.items, (player, i) => {
      const item = i as Phaser.GameObjects.Sprite;
      if (!item.visible) return;
      item.setVisible(false);

      if (item.texture.key === "items-gems") {
        this.life.value += 10;
        this.mana.value += 5;
      } else if (item.texture.key === "items-chests") {
        this.life.value += 20;
        this.mana.value += 10;
      }

      this.life.value = Math.min(100, this.life.value);
      this.mana.value = Math.min(100, this.mana.value);
    });

    // camera bounds
    // this.cameras.main.setBounds(
    //   0 - this.cameras.main.width / 2,
    //   0 - this.cameras.main.height / 2,
    //   this.tilemap.widthInPixels + this.cameras.main.width / 2,
    //   this.tilemap.heightInPixels + this.cameras.main.height / 2,
    // );

    this.cameras.main.startFollow(this.player);

    // resize
    globalThis.addEventListener("resize", () => {
      this.cameras.resize(globalThis.innerWidth, globalThis.innerHeight);
      joyStick.setPosition(75, this.cameras.main.height - 75);
      runButton.setPosition(
        this.cameras.main.width - 35,
        this.cameras.main.height - 35,
      );
    });

    this.lights.enable();
    this.lights.setAmbientColor(0x111111);

    // set tilemap walls to be shadow casting
    this.walls.setPipeline("Light2D");
    this.ground.setPipeline("Light2D");
    this.windows.setPipeline("Light2D");
    this.roof.setPipeline("Light2D");
    this.roof.setDepth(1000);
    this.doors.setPipeline("Light2D");
    this.items.forEach((item) => {
      item.setPipeline("Light2D");
    });

    this.playerLight = this.lights.addLight(
      this.player.x,
      this.player.y,
      1200,
      0xcccccc,
      1,
    );

    this.player.setPipeline("Light2D");
  }

  private animation = "down";
  private wasd?: { [key: string]: Phaser.Input.Keyboard.Key };
  private gameOver = false;

  update() {
    if (this.life.value <= 0 && !this.gameOver) {
      // player died
      this.player.setVelocity(0, 0);
      this.player.setActive(false);
      this.player.anims.play(`death`, true);

      this.time.delayedCall(2000, () => {
        // fade out
        this.cameras.main.fadeOut(
          5000,
          0,
          0,
          0,
          (_camera: Phaser.Cameras.Scene2D.Camera, value: number) => {
            if (value === 1) {
              this.time.delayedCall(2000, () => {
                this.scene.restart({});
              });
            }
          },
        );
      });

      this.life.value = 0;
      this.gameOver = true;
    }

    if (this.gameOver) return;

    // time of day light
    const time = new Date();
    const hours = time.getHours();

    if (hours >= 6 && hours < 18) {
      this.lights.setAmbientColor(0xffffff);
      // disable lights
      this.playerLight.setIntensity(0);
    } else {
      this.lights.setAmbientColor(0x111111);
      // enable lights
      this.playerLight.setIntensity(1);
    }

    this.playerLight.setPosition(this.player.x, this.player.y);

    const player = this.player;
    const tilemap = this.tilemap;

    let velocityX = 0;
    let velocityY = 0;

    const velocityRate = 1;

    // player movement
    let boost = 1;
    const boostDelay = this.player.data.get("boostDelay");
    const lastBoost = this.player.data.get("lastBoost");
    const boostDuration = this.player.data.get("boostDuration");
    let boostCost = this.player.data.get("boostCost");
    const boostChannellingCost = this.player.data.get("boostChannellingCost");
    const boostStart = this.player.data.get("boostStart");

    if (this.runButtonDown) {
      if (lastBoost + boostDuration > this.game.getTime()) {
        boost = 1.5;
      }

      if (boostStart + boostDelay < this.game.getTime()) {
        boostCost = boostChannellingCost;
      }

      if (
        lastBoost + boostDelay < this.game.getTime() &&
        this.mana.value >= boostCost
      ) {
        this.player.data.set("lastBoost", this.game.getTime());
        this.mana.value -= boostCost;
      }
    }
    const speed = 350 * boost;
    const playerBody = player.body as Phaser.Physics.Arcade.Body;

    [this.wasd, this.cursors].filter(Boolean).forEach((input) => {
      if (input!.left.isDown) {
        velocityX = -velocityRate;
      } else if (input!.right.isDown) {
        velocityX = velocityRate;
      }

      if (input!.up.isDown) {
        velocityY = -velocityRate;
      } else if (input!.down.isDown) {
        velocityY = velocityRate;
      }
    });

    // Normalize velocity so that diagonal movement isn't faster
    if (velocityX !== 0 && velocityY !== 0) {
      velocityX *= 0.7071;
      velocityY *= 0.7071;
    }

    // Apply velocity
    playerBody.setVelocity(velocityX * speed, velocityY * speed);

    if (velocityX === 0 && velocityY === 0) {
      playerBody.setVelocity(0, 0);
      player.anims.play(`idle-${this.animation}`, true);
    } else {
      // Determine the animation based on the direction of movement
      if (velocityX > 0) {
        this.animation = "right";
      } else if (velocityX < 0) {
        this.animation = "left";
      } else if (velocityY > 0) {
        this.animation = "down";
      } else if (velocityY < 0) {
        this.animation = "up";
      }
      player.anims.play("walk-" + this.animation, true);

      // check what tile the player is on
      const hotSpots = tilemap.getObjectLayer("HotSpots");
      const objects = hotSpots!.objects;
      // see if user stepped on a portal
      objects.forEach((obj) => {
        if (
          player.x >= obj.x! && player.x <= obj.x! + obj.width! &&
          player.y >= obj.y! && player.y <= obj.y! + obj.height!
        ) {
          if (obj.type === "portal") {
            if (obj) {
              this.cameras.main.fadeOut(1000, 0, 0, 0, () => {
                const props = obj.properties?.reduce((acc, prop) => {
                  acc[prop.name] = prop.value;
                  return acc;
                }, {});

                if (props?.x && props?.y) {
                  // move player to new location
                  player.setPosition(props.x * 24, props.y * 24);
                } else {
                  // reset
                  player.setPosition(451, 143);
                }
                this.cameras.main.fadeIn(1000, 0, 0, 0);
              });
            }
          }
        }
      });
    }

    // update enemy
    const enemy = this.enemy;
    const enemyBody = enemy.body as Phaser.Physics.Arcade.Body;
    const playerPos = player.getCenter();
    const enemyPos = enemy.getCenter();
    const distance = Phaser.Math.Distance.BetweenPoints(playerPos, enemyPos);

    const delay = enemy.data.values.attackDelay;

    if (enemy.data.values.lastHit + delay < this.game.getTime()) {
      if (
        distance < 250 && distance > 100
      ) {
        this.physics.accelerateToObject(enemy, player, 300);
      } else {
        this.physics.moveToObject(enemy, player, 500);
      }
    } else {
      enemyBody.setVelocity(0, 0);
    }

    enemy.setDepth(enemy.y);
    let direction = enemy.anims.currentAnim?.key.split("-")[1] ?? "down";
    let action = "idle";

    if (enemyPos.x < playerPos.x) {
      direction = "right";
    } else if (enemyPos.x > playerPos.x) {
      direction = "left";
    } else if (enemyPos.y < playerPos.y) {
      direction = "down";
    } else if (enemyPos.y > playerPos.y) {
      direction = "up";
    }

    if (!enemy.data.values.isAttacking) {
      if (distance > 50) {
        action = "walk";
      }
    } else {
      action = this.enemy.data.get("weapon") ?? "sword";
    }

    enemy.anims.play(`${action}-${direction}`, true);
  }

  /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
