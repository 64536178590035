import { Scene } from "~/games/deps.ts";
import { ui } from "~/games/signals.ts";
import ConnectMidi from "~/games/midi-gridi/components/ConnectMidi.tsx";
import { Device, DeviceInfo } from "~/games/midi-gridi/lib/midi.ts";

export class GameScene extends Scene {
    devices: Device[] = [];

    create() {
        ui.value = [
            ConnectMidi,
        ];

        this.game.events.on(
            "midi-device",
            (device: typeof this.devices[number]) => {
                this.devices.push(device);
                console.log("Connected to", device.input?.name, device);

                device.input?.addEventListener("midimessage", (event) => {
                    console.log(event.data);
                });
            },
        );

        this.input.keyboard?.on("keydown", (event: KeyboardEvent) => {
            if (event.key === " ") {
                this.devices.forEach((device) => {
                    const output = device.output;

                    output?.send([0x90, 41, 127]);
                    output?.send(
                        [0x90, 41, 0],
                        window.performance.now() + 1000,
                    );
                });
            }
        });
    }
    update() {
    }
}
