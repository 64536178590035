export function useColorSort(
    images: {
        key: string;
        src: string;
        elm: HTMLImageElement;
        dominantColor?: [number, number, number];
    }[],
) {
    return [...images].sort((a, b) => {
        let colorA = [];

        if (a.dominantColor) {
            colorA = a.dominantColor;
        } else {
            colorA = getDominantColor(a.elm);
            a.dominantColor = colorA;
        }

        let colorB = [];
        if (b.dominantColor) {
            colorB = b.dominantColor;
        } else {
            colorB = getDominantColor(b.elm);
            b.dominantColor = colorB;
        }

        return getRainbowOrder(colorA) - getRainbowOrder(colorB);
    });
}

function getDominantColor(image: HTMLImageElement): [number, number, number] {
    const canvas = document.createElement("canvas");
    canvas.width = image.width;
    canvas.height = image.height;

    canvas.classList.add("absolute", "inset-[.5rem]", "z-0");

    image.closest("button")?.appendChild(canvas);

    const ctx = canvas.getContext("2d", { willReadFrequently: true });
    if (!ctx) throw new Error("Couldn't get canvas context");

    ctx.filter = "blur(10px)";
    ctx.drawImage(image, 0, 0);

    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const { data } = imageData;

    const colorCount: Record<string, number> = {};
    let dominantColor: [number, number, number] = [0, 0, 0];
    let maxCount = 0;

    for (let i = 0; i < data.length; i += 4) {
        const r = data[i];
        const g = data[i + 1];
        const b = data[i + 2];

        // Normalize the color to reduce noise in slight variations
        const normalizedColor = `${Math.round(r / 10) * 10},${
            Math.round(g / 10) * 10
        },${Math.round(b / 10) * 10}`;

        colorCount[normalizedColor] = (colorCount[normalizedColor] || 0) + 1;

        if (colorCount[normalizedColor] > maxCount) {
            maxCount = colorCount[normalizedColor];
            const [rNorm, gNorm, bNorm] = normalizedColor.split(",").map(
                Number,
            );
            dominantColor = [rNorm, gNorm, bNorm];
        }
    }

    return dominantColor;
}

function getRainbowOrder([r, g, b]: [number, number, number]): number {
    // RGB to Hue conversion for better color sorting
    const [h, s, l] = rgbToHsl(r, g, b);

    const value = (() => {
        if (l > 0.9) return 70; // White
        if (s < 0.1) return 80; // Grayscale
        if (l < 0.1) return 90; // Black

        // Map Hue to Rainbow Order (0 to 360)
        if (h < 30 || h >= 330) return 0; // Red
        if (h >= 30 && h < 90) return 10; // Orange
        if (h >= 90 && h < 150) return 20; // Yellow
        if (h >= 150 && h < 210) return 30; // Green
        if (h >= 210 && h < 270) return 40; // Blue
        if (h >= 270 && h < 330) return 50; // Violet

        return 60; // fallback
    })();

    return value + l + s * 5;
}

function rgbToHsl(r: number, g: number, b: number): [number, number, number] {
    r /= 255, g /= 255, b /= 255;

    const max = Math.max(r, g, b), min = Math.min(r, g, b);
    let h = 0, s, l = (max + min) / 2;

    if (max === min) {
        h = s = 0; // achromatic
    } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0);
                break;
            case g:
                h = (b - r) / d + 2;
                break;
            case b:
                h = (r - g) / d + 4;
                break;
        }
        h /= 6;
    }

    return [h * 360, s, l];
}
