import { Scene } from "~/games/deps.ts";
import PlayerInputPlugin from "../src/PlayerInputPlugin.ts";

export class GameScene extends Scene {
    playerInputPlugin!: PlayerInputPlugin;

    preload() {
        this.playerInputPlugin = this.plugins.get(
            "PlayerInputPlugin",
        ) as PlayerInputPlugin;
    }

    create() {
        // Initialize four players and display controls
    }
}
