import { BaseGame, Scene } from "~/games/deps.ts";
import { ui } from "~/games/signals.ts";
import { ObjectWithRate } from "~/games/eikoo/types.ts";
import CountdownTimer from "~/games/eikoo/components/CountdownTimer.tsx";
import { BoxesGrid } from "~/games/boxes/lib/BoxesGrid.js";
import { Signal } from "@preact/signals";

export default class GameScene extends Scene {
  clickables: {
    button: Phaser.GameObjects.Text;
    speed: Phaser.Math.Vector2;
  }[] = [];
  possibilities: ObjectWithRate[] = [];
  screenRect: Phaser.Geom.Rectangle = new Phaser.Geom.Rectangle();
  grid!: BoxesGrid;
  text!: Phaser.GameObjects.Text;

  create() {
    ui.value = [
      ({ game }: { game: Signal<BaseGame> }) =>
        CountdownTimer({
          game,
          duration: 10 * 60 * 1000,
        }),
    ];

    this.cameras.main.setBackgroundColor(0x99ff99);

    // this.add.image(512, 384, "background").setAlpha(0.5);
    this.grid = new BoxesGrid(this, 100, 100, 800, 600, 40, 40);

    // this.clock = new Clock(this, {
    //   timeScale: 1,
    // });
    // this.clock.start();
    this.text = this.add.text(10, 10, "", {
      fontFamily: "Arial Black",
      fontSize: 38,
      color: "#000000",
      stroke: "#ffffff",
      strokeThickness: 4,
    });

    this.grid.players.forEach((player, i) => {
      player.text = this.add.text(
        this.scale.width - 10 - (i * 50),
        10,
        player.score.toString(),
        {
          fontFamily: "Arial Black",
          fontSize: 18,
          color: "#" + player.color.toString(16).padStart(6, "0"),
          stroke: "#ffffff",
          strokeThickness: 4,
          align: "right",
        },
      ).setOrigin(1, 0);
    });

    // this.input.on('pointerdown', this.clock.pause, this.clock);
    // this.input.on('pointerup', this.clock.resume, this.clock);

    // it's not already selected, select it, else deselect it
    // if startBlock is already selected, see if this is a valid move
    // if it is, add the line between the two blocks and deselect them
    // if it's not, deselect the startBlock
    // check to see if the line completed a square
    // if it did, add the square, add 1 point for the current play and check for a win
    // switch players
  }

  // getTimeString() {
  //   const s = this.clock.now / 1000;
  //   const hours = Math.floor(s / 3600);
  //   const minutes = Math.floor((s % 3600) / 60);
  //   const seconds = Math.floor(s % 60);
  //   const ms = Math.floor(this.clock.now / 10 % 100);
  //   return `${
  //     hours ? `${hours}:` : ""
  //   }${(minutes ? minutes.toString().padStart(2, "0") + ":" : "")}${
  //     seconds.toString().padStart(minutes ? 2 : 0, "0")
  //   }.${ms.toString().padStart(2, "0")}`;
  // }

  update() {
    this.grid.players.forEach((player, i) => {
      player.text.setText(player.score.toString());
    });
    this.text.setText(`Player ${this.grid.currentPlayer + 1}`);
    this.text.setColor(
      "#" +
        this.grid.players[this.grid.currentPlayer].color.toString(16).padStart(
          6,
          "0",
        ),
    );
  }
}
