import { signal } from "@preact/signals";
import { Game } from "./mod.ts";
import { ComponentChildren } from "preact";
// import Possibilities from "~/games/example/components/Possibilities.tsx";

export const selectedGame = signal<Game | undefined>(undefined);
export const assetsUri = signal<string | undefined>(undefined);

// export const UIComponents = [
//   Possibilities,
// ];

export const ui = signal<ComponentChildren | undefined>(undefined);
