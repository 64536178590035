import { BaseGame } from "~/games/deps.ts";
import { Signal } from "@preact/signals";

export default function SocketStatus({ game }: { game: Signal<BaseGame> }) {
    const socket = game.value?.socket;

    if (!socket) return <></>;

    const states = {
        connecting: "🟡",
        closing: "⚠️",
        connected: "🟢",
        closed: "🔴",
        unknown: "❓",
    };

    return (
        <div
            class="text-base pointer-events-auto absolute right-0 top-0 p-2"
            title={socket.status}
        >
            {states[socket.status as keyof typeof states]}
        </div>
    );
}
