import { Signal, useSignal } from "@preact/signals";
import { useEffect, useRef } from "preact/hooks";
import { BaseGame } from "~/games/deps.ts";
import { Button } from "~/components/Button.tsx";
import type { Document } from "jsr:@olli/kvdex";
import { GameType } from "~/lib/models.ts";

export const Lobby = (
    { gameInfo, onConnect }: {
        gameInfo: Record<"name" | "key" | string, string>;
        onConnect: (id: string | null) => void;
    },
) => {
    const gamesQuery = useSignal<
        Document<GameType, string>[] | undefined
    >(undefined);
    const cursor = useSignal<string | undefined>(undefined);
    const params = new URLSearchParams(globalThis.location.search);
    const search = useSignal(params.get("search") ?? "");
    const doRefresh = useSignal(true);
    const loading = useSignal(false);
    const error = useSignal<string | null>(null);

    useEffect(() => {
        if (!doRefresh.value) {
            return;
        }

        let url = `/api/games/${gameInfo.key}`;
        if (search.value) {
            url += search.value;
        }
        loading.value = true;
        error.value = null;

        fetch(url, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
        }).then((res) => res.json()).then(
            (data) => {
                if (data.error) {
                    error.value = data.error;
                    return;
                }
                gamesQuery.value = data.result;
                cursor.value = data.cursor;
                doRefresh.value = false;

                if (search.value) {
                    globalThis.history.pushState(
                        {},
                        "",
                        `?search=${search.value}`,
                    );
                } else {
                    const url = new URL(globalThis.location.href);
                    url.searchParams.delete("search");
                    globalThis.history.pushState(
                        {},
                        "",
                        url.toString(),
                    );
                }
            },
        ).finally(() => {
            loading.value = false;
        });
    }, [doRefresh.value]);

    return (
        <div class="ui flex flex-col items-center p-4 min-h-screen text-zinc-200 bg-slate-900">
            <div class="max-w-screen-2xl mx-auto w-full">
                <div class="flex justify-between items-center gap-8">
                    <Button
                        class="text-2xl"
                        color="danger"
                        onClick={() => onConnect(null)}
                        title="back"
                        autoFocus
                    >
                        <span aria-hidden="true">←</span>
                    </Button>
                    <h1 class="text-3xl font-bold capitalize text-center">
                        {gameInfo.name} Games
                    </h1>
                    <div></div>
                </div>
                <form
                    class="flex gap-4 items-end my-4 text-xl justify-center"
                    onSubmit={(e) => {
                        e.preventDefault();
                        fetch(
                            `/api/games/${gameInfo.key}?search=${search.value}`,
                        ).then((res) => res.json()).then((data) => {
                            gamesQuery.value = data.result;
                            cursor.value = data.cursor;
                        });
                    }}
                >
                    <div class="flex flex-col">
                        <label for="game-name">Game</label>
                        <input
                            id="game-name"
                            type="search"
                            class="text-zinc-800 px-2 py-1 rounded"
                            value={search.value}
                            onInput={(
                                e,
                            ) => {
                                search.value = e.currentTarget.value;
                                if (search.value === "") {
                                    doRefresh.value = true;
                                }
                            }}
                        />
                    </div>
                    <div class="flex gap-2">
                        <Button>Search</Button>
                        <Button
                            onClick={() => {
                                doRefresh.value = true;
                            }}
                        >
                            Refresh
                        </Button>
                    </div>
                </form>

                {error.value && (
                    <p class="my-4 bg-red-300 text-red-800 p-4 border-red-800">
                        {error.value}
                    </p>
                )}

                {loading.value && (
                    <p class="my-4 text-center bg-blue-200 text-blue-800 p-4 border-blue-800">
                        Loading...
                    </p>
                )}

                {gamesQuery.value?.length
                    ? (
                        <>
                            <table class="w-full border">
                                <thead>
                                    <tr class="[&>th]:text-start">
                                        <th>Game</th>
                                        <th>Players</th>
                                        <th>Mode</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {gamesQuery.value.map((game) => (
                                        <tr>
                                            <td>
                                                <button
                                                    onClick={() => {
                                                        onConnect(
                                                            game.value.slug,
                                                        );
                                                    }}
                                                >
                                                    {game.value.name}
                                                </button>
                                            </td>
                                            <td>
                                                {game.value.players
                                                    .length}/{game.value
                                                    .maxPlayers}
                                            </td>
                                            <td>{game.value.mode}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {cursor.value && (
                                <div class="flex justify-center gap-4">
                                    <Button
                                        onClick={() => {
                                            fetch(
                                                `/api/games/${gameInfo.key}?cursor=${cursor.value}`,
                                            ).then((res) => res.json()).then(
                                                (data) => {
                                                    gamesQuery.value = [
                                                        data.result,
                                                        ...gamesQuery.value ??
                                                            [],
                                                    ];
                                                    cursor.value = data.cursor;
                                                },
                                            );
                                        }}
                                    >
                                        Load More
                                    </Button>
                                </div>
                            )}
                        </>
                    )
                    : <p class="text-center">No games found</p>}
            </div>
        </div>
    );
};

export const HostDialog = (
    { gameInfo, onConnect }: {
        gameInfo: Record<"key" | string, string>;
        onConnect: (id: string | null) => void;
    },
) => {
    const ref = useRef<HTMLDialogElement>(null);
    useEffect(() => {
        if (ref.current) {
            ref.current.showModal();
        }
    }, [ref.current]);

    return (
        <dialog class="ui" ref={ref}>
            <h1>Host Game</h1>
            <form
                action={`/api/games/${gameInfo.key}`}
                method="POST"
                onSubmit={(e) => {
                    const form = e.currentTarget;
                    e.preventDefault();

                    const getValue = (id: string) =>
                        form.querySelector<HTMLInputElement>(`#${id}`)?.value;

                    fetch(form.action, {
                        method: form.method,
                        body: JSON.stringify({
                            name: getValue("game-name"),
                            players: Number(getValue("players")),
                            mode: getValue("mode"),
                        }),
                    })
                        .then((res) => res.json())
                        .then((data) => {
                            onConnect(data.result.slug);
                        });
                }}
            >
                <label for="game-name">Game</label>
                <input id="game-name" type="text" />
                <label for="players">Players</label>
                <input id="players" type="number" />
                <label for="mode">Mode</label>
                <input id="mode" type="text" />
                <button type="submit">Host</button>
            </form>
        </dialog>
    );
};

export default function LobbyBrowser(
    { game, onConnect }: {
        game: Signal<BaseGame>;
        onConnect: (id: string | null) => void;
    },
) {
    const gameInfo = game.value.info;
    const focus = useSignal<"lobby" | "host" | "connecting" | null>(null);

    if (focus.value === null) {
        return (
            <div class="flex flex-col gap-4 items-center">
                <h1 class="capitalize">{gameInfo.name}</h1>
                <div class="ui flex gap-4 justify-center">
                    <Button
                        autoFocus
                        onClick={() => focus.value = "lobby"}
                    >
                        Join
                    </Button>
                    <Button
                        onClick={() => focus.value = "host"}
                    >
                        Host
                    </Button>
                </div>
            </div>
        );
    }

    if (focus.value === "host") {
        return (
            <HostDialog
                gameInfo={gameInfo}
                onConnect={onConnect}
            />
        );
    }
    return <Lobby gameInfo={gameInfo} onConnect={onConnect} />;
}
