import { AUTO, Scale } from "../deps.ts";

export const config = {
  type: AUTO,
  width: 640,
  height: 512,
  mode: Scale.FIT,
  autoCenter: Scale.CENTER_BOTH,
  physics: {
    default: "arcade",
    arcade: {
      // debug: true,
      // debugShowBody: true,
      // debugShowStaticBody: true,
    },
  },
  pixelArt: true,
  disableContextMenu: true,
  plugins: {
    scene: [],
  },
};
