import { Scene } from "~/games/deps.ts";
import { ui } from "~/games/signals.ts";
import GameMenu from "~/games/eikoo/components/GameMenu.tsx";
import { isGameOver } from "~/games/eikoo/signals.ts";

export default class GameOverScene extends Scene {
  quitButton?: Phaser.GameObjects.Text;
  gameURL?: string;
  startButton?: Phaser.GameObjects.Text;
  preload() {
    isGameOver.value = true;
    ui.value = [
      GameMenu,
    ];
    this.game.pause();
  }
}
