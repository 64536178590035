import { BaseGame } from "../deps.ts";
import { config } from "./config.ts";
import BootScene from "./scenes/Boot.ts";
import { ui } from "~/games/signals.ts";
import MobileDemo from "~/games/coolrpg/scenes/MobileDemo.ts";

export default class Game extends BaseGame {
    constructor(props: { id: string; baseURL?: string }) {
        console.log("Game constructor", props);
        super({
            ...props,
            id: props.id,
            baseURL: props.baseURL,
            config,
            bootScene: BootScene,
            ui,
            scenes: {
                game: MobileDemo,
            },
            hasApi: true,
        });
    }
}

export const GameInfo = {
    class: Game,
    name: "Cool RPG",
    description: "A cool RPG game",
    thumbnail: "/games/coolrpg/thumbnail.jpg",
};
