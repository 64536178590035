import { Scene } from "~/games/deps.ts";
import { ui } from "~/games/signals.ts";
import CountdownTimer from "~/games/eikoo/components/CountdownTimer.tsx";

export class GameScene extends Scene {
    create() {
        ui.value = [
            CountdownTimer,
        ];
    }
    update() {
    }
}
