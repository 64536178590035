import { Scene } from "phaser/types/phaser.d.ts";

export function Button(
  scene: Scene,
  text: string,
  x: number,
  y: number,
  onClick: () => void,
  fontSize = 24,
) {
  const button = scene.add.text(
    x,
    y,
    text,
    {
      fontFamily: "sans-serif",
      fontSize: `${fontSize}px`,
      color: "#fff",
    },
  ).setInteractive({ useHandCursor: true }) as Phaser.GameObjects.Text;
  button.setOrigin(.5, .5);

  button.on("pointerup", onClick);

  return button;
}
