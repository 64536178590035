// You can write more code here
import { Signal } from "@preact/signals";
import { Scene } from "~/games/deps.ts";
import { ui } from "~/games/signals.ts";
import Game from "../Game.ts";
import { Button } from "~/components/Button.tsx";
import { ImageList } from "~/games/sprite-packer/components/ImageList.tsx";
import { detector } from "~/games/sprite-packer/signals.ts";

/* START OF COMPILED CODE */

export default class GameScene extends Scene {
  constructor() {
    super("game");

    /* START-USER-CTR-CODE */
    // Write your code here.
    /* END-USER-CTR-CODE */
  }

  editorCreate(): void {
    this.events.emit("scene-awake");
  }

  /* START-USER-CODE */

  editImageSprite: Phaser.GameObjects.Sprite | null = null;
  editImage: { key: string; src: string; elm: HTMLImageElement } | null = null;
  cameraTarget: Phaser.GameObjects.Container | null = null;
  frames: Phaser.GameObjects.Container | null = null;
  activeFrame: Phaser.GameObjects.Rectangle | null = null;

  // Write your code here

  create() {
    this.editorCreate();

    // get all images that were preloaded
    const uuidPattern =
      /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

    const images = Object.entries(this.textures.list).map(([key, value]) => ({
      key,
      elm: value.source[0].image,
      src: value.source[0].image.src,
    })).filter((image) =>
      !image.key.startsWith("_") && image.key.search(uuidPattern) === -1
    );

    ui.value = [
      ({ game }: { game: Signal<Game> }) => [
        Button({
          class: "absolute top-0 right-0",
          children: "Quit",
          onClick: () => game.value.quit(),
        }),
        ImageList({ scene: this, images }),
      ],
    ];

    this.cameraTarget = this.add.container(0, 0);
    this.cameras.main.startFollow(this.cameraTarget, true, 0.1, 0.1);

    // group for holding frame rects
    this.frames = this.add.container();
    const baseSize = [8, 16];
    const baseColor = 0xff0000;
    const activeColor = 0x00ff00;
    const baseOpacity = 0.4;
    this.activeFrame = null;

    this.events.on(
      "edit-image",
      (props: { key: string; src: string; elm: HTMLImageElement }) => {
        console.log("edit-image", props);
        this.editImage = props;

        if (this.editImageSprite) {
          this.editImageSprite.destroy();
        }

        if (this.frames) {
          // destroy all frames
          this.frames.removeAll(true);
        }
        this.activeFrame = null;

        this.editImageSprite = this.add.sprite(0, 0, props.key);
        this.editImageSprite.setOrigin(.5, .5);
        this.editImageSprite.setInteractive();

        // line up frames container with sprite
        this.frames?.setPosition(
          this.editImageSprite.x - this.editImageSprite.width / 2,
          this.editImageSprite.y - this.editImageSprite.height / 2,
        );

        // show border around sprite
        const border = this.add.rectangle(
          0,
          0,
          this.editImageSprite.width,
          this.editImageSprite.height,
          0xffffff,
          .2,
        );
        border.setOrigin(0, 0);
        this.frames?.add(border);

        this.frames?.setDepth(1);

        this.editImageSprite.on(
          "pointerdown",
          (pointer: Phaser.Input.Pointer) => {
            if (!this.editImageSprite) return;

            const x = pointer.worldX - this.editImageSprite.x +
              this.editImageSprite.width / 2;
            const y = pointer.worldY - this.editImageSprite.y +
              this.editImageSprite.height / 2;

            const frame = this.add.rectangle(
              x,
              y,
              baseSize[0],
              baseSize[1],
              baseColor,
              baseOpacity,
            );
            this.frames?.add(frame);

            frame.setInteractive();
            frame.on("pointerdown", (_pointer: Phaser.Input.Pointer) => {
              if (this.activeFrame) {
                this.activeFrame.setFillStyle(baseColor, baseOpacity);
              }

              this.activeFrame = frame;
              this.activeFrame.setFillStyle(activeColor, baseOpacity);
            });

            // cancel the event
            pointer.event.preventDefault();
          },
        );

        this.cameraTarget?.setPosition(0, 0);
        this.cameraTarget?.setSize(
          this.editImageSprite.width,
          this.editImageSprite.height,
        );
        // zoom
        const scale = Math.min(
          this.cameras.main.width / this.editImageSprite.width,
          this.cameras.main.height / this.editImageSprite.height,
        );
        this.cameras.main.setZoom(scale);
      },
    );

    // mouse wheel zoom
    this.input.on("wheel", (pointer, gameObjects, deltaX, deltaY, deltaZ) => {
      const zoom = this.cameras.main.zoom + deltaY * -0.001;
      this.cameras.main.setZoom(zoom);
    });

    // mouse drag
    this.input.on("pointermove", (pointer) => {
      if (pointer.middleButtonDown()) {
        // more zoom, less drag
        const scale = 1 / this.cameras.main.zoom;

        this.cameraTarget!.x += (pointer.prevPosition.x - pointer.position.x) *
          scale;
        this.cameraTarget!.y += (pointer.prevPosition.y - pointer.position.y) *
          scale;
      }
    });
  }

  /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
