import { BaseGame } from "../deps.ts";
import { config } from "./config.ts";
import BootScene from "./scenes/Boot.ts";
import { ui } from "~/games/signals.ts";
import { GameScene } from "./scenes/GameScene.ts";

export default class Game extends BaseGame {
  constructor(props: { id: string; baseURL?: string }) {
    console.log("Game constructor", props, GameScene);
    super({
      ...props,
      id: props.id,
      baseURL: props.baseURL,
      config,
      bootScene: BootScene,
      ui,
      scenes: {
        game: GameScene,
      },
    });
  }
}

export const GameInfo = {
  class: Game,
  name: "Player Input Plugin",
  description: "Map player input to game actions",
  thumbnail: "/games/thumbnail.jpg",
};
