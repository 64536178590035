import { BaseGame } from "../deps.ts";
import { config } from "./config.ts";
import BootScene from "./scenes/Boot.ts";
import GameOverScene from "~/games/eikoo/scenes/GameOverScene.ts";
import GameScene from "./scenes/GameScene.ts";
import { ui } from "~/games/signals.ts";

export default class EikooGame extends BaseGame {
  constructor(props: { id: string; baseURL?: string }) {
    super({
      id: props.id,
      bootScene: BootScene,
      scenes: {
        "game": GameScene,
        "game-over": GameOverScene,
      },
      ui,
      config,
    });
  }
}

export const GameInfo = {
  class: EikooGame,
  name: "Eikoo",
  description: "Click all the things",
  thumbnail: "/games/eikoo/thumbnail.png",
};
