import { BaseGame } from "../deps.ts";
import { config } from "./config.ts";
import BootScene from "./scenes/BootScene.ts";
import { ui } from "~/games/signals.ts";
import GameScene from "./scenes/GameScene.ts";

export default class Game extends BaseGame {
    constructor(props: { id: string; baseURL?: string }) {
        super({
            config,
            id: props.id,
            bootScene: BootScene,
            ui,
            scenes: {
                game: GameScene,
            },
        });
    }
}

export const GameInfo = {
    class: Game,
    name: "Sprite Packer",
    description: "Utilities for packing sprites",
    thumbnail: "/games/sprite-packer/thumbnail.webp",
};
