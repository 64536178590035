import { Signal, useSignal } from "@preact/signals";
import { useEffect } from "preact/hooks";
import { BaseGame } from "~/games/deps.ts";
import IconMusic from "tabler_icons_tsx/music.tsx";
import IconMusicPause from "tabler_icons_tsx/music-pause.tsx";
import CountdownTimer from "~/games/eikoo/components/CountdownTimer.tsx";

export const MusicPlayer = ({ game }: { game: Signal<BaseGame> }) => {
    const music = useSignal<
        { key: string; play: () => void; stop: () => void }[]
    >([]);
    const playingIndex = useSignal<number>(0);
    const isPlaying = useSignal<boolean>(
        (localStorage.getItem("MusicPlayer.isPlaying") ?? "false") === "true",
    );
    const pauseOnBlur = useSignal<boolean>(
        (localStorage.getItem("MusicPlayer.pauseMusicOnBlur") ?? "true") ===
            "true",
    );
    const shuffle = useSignal<boolean>(
        (localStorage.getItem("MusicPlayer.shuffle") ?? "false") === "true",
    );
    const startedPlaying = useSignal<number>(0);

    const next = (dir = 1) => {
        if (isPlaying.value) {
            music.value[playingIndex.value].stop();
        }
        const value = ((playingIndex.value) + dir + music.value.length) %
            music.value.length;
        if (isPlaying.value) {
            music.value[value].play();
        }
        playingIndex.value = value;
    };

    useEffect(() => {
        localStorage.setItem(
            "MusicPlayer.isPlaying",
            isPlaying.value.toString(),
        );

        if (isPlaying.value) {
            startedPlaying.value = performance.now();
        }
    }, [
        isPlaying.value,
    ]);

    const toggleShuffle = (value?: boolean) => {
        shuffle.value = value ?? !shuffle.value;
        const track = music.value[playingIndex.value];
        const sortedMusic = [...music.value];
        if (shuffle.value) {
            sortedMusic.sort(() => Math.random() - 0.5);
        } else {
            sortedMusic.sort((a, b) => a.key.localeCompare(b.key));
        }

        playingIndex.value = sortedMusic.indexOf(track);
        music.value = sortedMusic;

        localStorage.setItem("MusicPlayer.shuffle", shuffle.value.toString());

        return track;
    };

    useEffect(() => {
        if (!game.value) return;

        const sortedMusic = game
            .value?.sound.jsonCache.entries.get(
                globalThis.location.href.split("/")[4],
            )?.music
            .files.map(
                (file: { type: string; key: string }) => {
                    if (file.type !== "audio") return null;
                    return {
                        key: file.key,
                        play: () => {
                            game.value?.sound.play(file.key);
                            startedPlaying.value = performance.now();

                            const track = game.value?.sound.get(file.key);

                            // when finished, play the next track
                            track.once("complete", () => {
                                next();
                            });
                        },
                        stop: () => {
                            game.value?.sound.stopByKey(file.key);
                        },
                    };
                },
            );
        music.value = sortedMusic;
        toggleShuffle(shuffle.value);
        game.value.sound.pauseOnBlur = pauseOnBlur.value;

        if (isPlaying.value) {
            playingIndex.value = 0;
            music.value[0].play();
        }
    }, [game.value]);

    if (!music.value?.length) return <>No Music</>;

    const toggleBgMusic = () => {
        const value = !pauseOnBlur.value;
        game.value.sound.pauseOnBlur = value;
        localStorage.setItem(
            "MusicPlayer.pauseMusicOnBlur",
            value.toString(),
        );
        pauseOnBlur.value = value;
    };

    const track = music.value[playingIndex.value];
    const duration = game.value.sound.get(track.key)?.duration * 1000;

    return (
        <div class="pointer-events-auto absolute bottom-0 right-0 p-2">
            <div class="flex items-end text-base">
                <div class="flex flex-col group relative items-start">
                    {music.value.map((
                        track,
                        i,
                    ) => (
                        <button
                            class={`hover:bg-slate-800 cursor-pointer px-2 ${
                                playingIndex.value === i
                                    ? "text-green-400 block"
                                    : "hidden group-hover:block group-hover:text-blue-400 group-focus-within:block"
                            }`}
                            onDblClick={(e) => {
                                if (
                                    isPlaying.value
                                ) {
                                    music.value[playingIndex.value].stop();
                                }
                                playingIndex.value = i;
                                isPlaying.value = true;
                                music.value[playingIndex.value].play();

                                // blur the button
                                (e.target as HTMLElement).blur();
                            }}
                            onClick={(e) => {
                                if (
                                    isPlaying.value
                                ) {
                                    music.value[playingIndex.value].stop();
                                }
                                playingIndex.value = i;
                                if (isPlaying.value) {
                                    music.value[playingIndex.value].play();
                                }
                                // blur the button
                                (e.target as HTMLElement).blur();
                            }}
                        >
                            {track.key}
                        </button>
                    ))}
                </div>
                <div class="relative flex flex-col items-start">
                    <div>
                        <button
                            class=""
                            onClick={() => next(-1)}
                        >
                            ⏮
                        </button>
                        {isPlaying.value
                            ? (
                                <button
                                    class=""
                                    onClick={() => {
                                        track.stop();
                                        isPlaying.value = false;
                                    }}
                                >
                                    ⏹
                                </button>
                            )
                            : (
                                <button
                                    class=""
                                    onClick={() => {
                                        track.play();
                                        isPlaying.value = true;
                                    }}
                                >
                                    ▶️
                                </button>
                            )}

                        <button
                            class=""
                            onClick={() => next()}
                        >
                            ⏭
                        </button>

                        <button
                            class=""
                            onClick={() => toggleShuffle()}
                        >
                            {shuffle.value ? "🔀" : "🔁"}
                        </button>

                        <button
                            class="hover:bg-slate-800 cursor-pointer relative pl-2 text-xs py-1"
                            onClick={toggleBgMusic}
                            title={`${
                                !pauseOnBlur.value ? "Play" : "Pause"
                            } sound when in background`}
                        >
                            {pauseOnBlur.value
                                ? <IconMusicPause size={20} />
                                : <IconMusic size={20} />}
                            <span class="absolute top-0 left-1 shadow">
                                BG
                            </span>
                        </button>
                    </div>
                    {isPlaying.value && (
                        <div class="absolute bottom-2 right-6 text-sm [&>div]:text-sm">
                            <CountdownTimer
                                game={game}
                                duration={duration}
                                elapsed={performance.now() -
                                    startedPlaying.value}
                                key={`music-${track.key}`}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
