import {
    connectMidiDevice,
    Device,
    DeviceInfo,
    listMidiDevices,
} from "~/games/midi-gridi/lib/midi.ts";
import { useEffect, useState } from "preact/hooks";
import { Signal } from "@preact/signals";
import { BaseGame } from "~/games/deps.ts";
import { Button } from "~/components/Button.tsx";

export default function ConnectMidi({ game }: { game?: Signal<BaseGame> }) {
    const [devices, setDevices] = useState<DeviceInfo[]>([]);
    const [selectedDevice, setSelectedDevice] = useState<
        Device | null
    >(
        null,
    );

    useEffect(() => {
        listMidiDevices().then((devices) => {
            setDevices(devices);
            console.log("MIDI devices", devices);
            game?.value?.events.emit("midi-devices", devices);
        });
    }, []);

    return (
        <div>
            {!(selectedDevice?.input && selectedDevice?.output)
                ? (
                    <>
                        <h1>Connect MIDI</h1>
                        <ul>
                            {devices.map((device) => (
                                <li key={device.id}>
                                    <Button
                                        type="button"
                                        onClick={async () => {
                                            const d = await connectMidiDevice(
                                                device.id,
                                            );
                                            const newDevices = {
                                                input: d.input ??
                                                    selectedDevice?.input,
                                                output: d.output ??
                                                    selectedDevice?.output,
                                            };

                                            setSelectedDevice(newDevices);

                                            if (
                                                newDevices.input &&
                                                newDevices.output
                                            ) {
                                                game?.value?.events.emit(
                                                    "midi-device",
                                                    newDevices,
                                                );
                                            }
                                        }}
                                    >
                                        {device.name} ({device.type})
                                    </Button>
                                </li>
                            ))}
                        </ul>
                    </>
                )
                : <h1>Connected to {selectedDevice.input?.name}</h1>}
        </div>
    );
}
