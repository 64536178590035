export default function Orb({
    type,
    value,
    max = 100,
}: {
    type: string;
    value: number;
    max?: number;
}) {
    const tailwindClasses = {
        item: "relative",
        orb: "col-span-2",
        buttons: "col-span-2",
        orbContainer:
            "max-w-full w-[150px] h-[150px] rounded-full relative overflow-hidden bg-black bg-opacity-40 border-[#66666622] border-2",
        manaOrb: "bg-[#00005a]",
        lifeOrb: "bg-[#630000]",
        glass:
            "rounded-full z-99 absolute w-full h-full bottom-0 left-0 shadow-glass z-[99]",
        /*
            &:after {
			content: '';
			width: 0px;
			height: 0px;
			border-radius: 50%;
			box-shadow: 0px 0px 15px 7px #fff;
			top: 40%;
			right: 35%;
		}*/
        glassAfter:
            "absolute w-0 h-0 rounded-full shadow-bloom top-[40%] right-[35%]",
        amount:
            "bg-blend-color bg-[url(https://www.transparenttextures.com/patterns/nice-snow.png)] absolute w-full h-57% bottom-0 left-0 animate-liquid transition-height duration-300",
        text: "absolute bottom-0 w-full text-center text-white text-lg",
    };

    const v = Math.min(Math.max(value, 0), max);
    const percent = (v / max) * 100;

    return (
        <>
            <div className={tailwindClasses.item}>
                <div
                    className={`${tailwindClasses.orbContainer}`}
                >
                    <div className={tailwindClasses.glass}>
                        <div className={tailwindClasses.glassAfter}></div>
                    </div>
                    <div
                        className={`${tailwindClasses.amount} ${
                            tailwindClasses[
                                `${type}Orb` as keyof typeof tailwindClasses
                            ]
                        }`}
                        style={{
                            height: `${percent}%`,
                        }}
                    >
                    </div>
                </div>
                <div class={tailwindClasses.text}>
                    {value}/{max}
                </div>
            </div>
        </>
    );
}
