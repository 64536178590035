import { AUTO, Scale } from "../deps.ts";
import BoardPlugin from "phaser3-rex-plugins/plugins/board-plugin.js";
import ClockPlugin from "phaser3-rex-plugins/plugins/clock-plugin.js";

export const config = {
  type: AUTO,
  width: 1024,
  height: 768,
  parent: "game-container",
  backgroundColor: "#028af8",
  antialias: true,
  scale: {
    mode: Scale.FIT,
    autoCenter: Scale.CENTER_BOTH,
  },
  physics: {
    default: "arcade",
    arcade: {
      gravity: { y: 0, x: 0 },
      // debug: true,
      // debugShowBody: true,
      // debugShowStaticBody: true,
    },
  },
} as const;
