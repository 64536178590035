export type DeviceInfo = {
    id: string;
    name: string | null;
    manufacturer: string | null;
    state: string;
    type: string;
};

export type Device = {
    input?: MIDIInput;
    output?: MIDIOutput;
};

export async function listMidiDevices() {
    const midiAccess = await navigator.requestMIDIAccess();

    const devices: DeviceInfo[] = [];
    for (
        const input of [
            ...midiAccess.inputs.values(),
            ...midiAccess.outputs.values(),
        ]
    ) {
        devices.push({
            id: input.id,
            name: input.name,
            manufacturer: input.manufacturer,
            state: input.state,
            type: input.type,
        });
    }

    return devices;
}

export async function connectMidiDevice(deviceId: string) {
    const midiAccess = await navigator.requestMIDIAccess();
    const input = midiAccess.inputs.get(deviceId);
    const output = midiAccess.outputs.get(deviceId);

    return { input, output };
}
