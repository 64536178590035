import { Scene } from "~/games/deps.ts";
import { Button } from "~/games/components/button.ts";
import EikooGame from "../Game.ts";

export default class BootScene extends Scene {
  quitButton?: Phaser.GameObjects.Text;
  gameURL?: string;
  startButton?: Phaser.GameObjects.Text;
  preload() {
    this.gameURL = window.location.href;
    this.load.baseURL =
      (this.scene.settings.data as { baseURL: string }).baseURL || "/";

    this.load.audio("crossroads-bg", "assets/music/crossroads-bg.mp3");

    this.startButton = Button(
      this,
      "Start",
      this.cameras.main.centerX,
      this.cameras.main.centerY,
      () => {
        this.startButton?.destroy();
        this.scene.start("game");
      },
      36,
    );

    Button(
      this,
      "Quit",
      this.cameras.main.centerX,
      this.cameras.main.height - 15,
      (this.game as EikooGame).quit,
    );
  }

  create() {
    this.sound.pauseOnBlur = false;
    this.sound.play("crossroads-bg", { loop: true });
  }
}
