import { Scene } from "~/games/deps.ts";
import { Button } from "~/games/components/button.ts";
import Game from "../Game.ts";

export default class BootScene extends Scene {
  quitButton?: Phaser.GameObjects.Text;
  gameURL?: string;
  startButton?: Phaser.GameObjects.Text;

  progressBar?: Phaser.GameObjects.Rectangle;
  progressBarBg?: Phaser.GameObjects.Rectangle;
  loadingText?: Phaser.GameObjects.Text;

  preload() {
    this.load.baseURL = "/";
    this.load.pack("asset-pack", "assets/midi-gridi-pack.json");
  }

  editorCreate(): void {
    // progressBar
    const progressBarX = this.cameras.main.centerX - 128;
    const progressBarY = this.cameras.main.height - 30;
    const progressBar = this.add.rectangle(progressBarX, progressBarY, 256, 20);
    progressBar.setOrigin(0, 0);
    progressBar.isFilled = true;
    progressBar.fillColor = 14737632;

    // preloadUpdater
    const fullWidth = progressBar.width;

    this.load.on(Phaser.Loader.Events.PROGRESS, (p: number) => {
      progressBar.width = fullWidth * p;
    });

    // progressBarBg
    const progressBarBg = this.add.rectangle(
      progressBarX,
      progressBarY,
      256,
      20,
    );
    progressBarBg.setOrigin(0, 0);
    progressBarBg.fillColor = 14737632;
    progressBarBg.isStroked = true;

    // loadingText
    const loadingText = this.add.text(
      progressBarX - 1,
      progressBarY - 23,
      "",
      {},
    );
    loadingText.text = "Loading...";
    loadingText.setStyle({
      "color": "#e0e0e0",
      "fontFamily": "arial",
      "fontSize": "20px",
    });

    this.events.emit("scene-awake");

    this.progressBar = progressBar;
    this.progressBarBg = progressBarBg;
    this.loadingText = loadingText;

    // bg music
    this.sound.pauseOnBlur = true;
  }

  create() {
    this.progressBar?.destroy();
    this.progressBarBg?.destroy();
    this.loadingText?.destroy();

    this.startButton = Button(
      this,
      "Play",
      this.cameras.main.centerX,
      this.cameras.main.centerY,
      () => {
        // fullscreen
        this.scale.startFullscreen();
        this.startButton?.destroy();
        this.scene.start("game");
      },
      36,
    );

    Button(
      this,
      "Quit",
      this.cameras.main.centerX,
      this.cameras.main.height - 15,
      (this.game as Game).quit,
    );

    this.game.events.emit("booted");
  }
}
