import { BaseGame, Scene } from "~/games/deps.ts";
import { ui } from "~/games/signals.ts";
import LobbyBrowser from "~/games/components/lobby/Browser.tsx";
import type { Signal } from "@preact/signals";

export class LobbyScene extends Scene {
    create() {
        ui.value = [
            ({ game }: { game: Signal<BaseGame> }) =>
                LobbyBrowser({
                    game,
                    onConnect: (id) => {
                        this.game.scene.start("game", { id });
                        globalThis.history.pushState(
                            {},
                            "",
                            `/play/hold-em/${id}`,
                        );
                    },
                }),
        ];
    }
}
