import { IS_BROWSER } from "$fresh/runtime.ts";
import { Signal } from "@preact/signals";
import { Message } from "~/games/coolrpg/types.ts";

// hack to make phaser work with SSR
export const PhaserGame =
  (IS_BROWSER ? Phaser.Game : class Game {}) as typeof Phaser.Game;
export const Scene =
  (IS_BROWSER ? Phaser.Scene : class Scene {}) as typeof Phaser.Scene;
export const Scale =
  (IS_BROWSER ? Phaser.Scale : class Scale {}) as typeof Phaser.Scale;
export const AUTO = (IS_BROWSER ? Phaser.AUTO : "auto") as typeof Phaser.AUTO;

export const Sprite =
  (IS_BROWSER
    ? Phaser.GameObjects.Sprite
    : class Sprite {}) as typeof Phaser.GameObjects.Sprite;

export const ArcadeSprite =
  (IS_BROWSER
    ? Phaser.Physics.Arcade.Sprite
    : class ArcadeSprite {}) as typeof Phaser.Physics.Arcade.Sprite;

export class BaseGame extends PhaserGame {
  bootScene: Phaser.Scene | null = null;
  private _socket: WebSocket | null = null;
  hasApi: boolean = false;
  info: Record<"name" | "description" | "thumbnail", string>;

  get socket() {
    const socket = this._socket;
    if (!socket) {
      throw new Error("Socket not connected");
    }

    const states = {
      [WebSocket.CONNECTING]: "connecting",
      [WebSocket.OPEN]: "connected",
      [WebSocket.CLOSING]: "closing",
      [WebSocket.CLOSED]: "closed",
    };

    return {
      send: (message: Message) => {
        socket.send(JSON.stringify(message));
      },
      status: states[socket.readyState as keyof typeof states] ?? "unknown",
    };
  }

  set connection(socket: WebSocket) {
    this._socket = socket;
  }

  ui: Signal;

  constructor(
    props: {
      id: string;
      baseURL?: string;
      bootScene: typeof Phaser.Scene | null;
      config: Phaser.Types.Core.GameConfig;
      ui?: Signal<unknown>;
      scenes?: Record<string, typeof Phaser.Scene>;
      hasApi?: boolean;
      info?: Record<"name" | "description" | "thumbnail" | "key", string>;
    },
  ) {
    if (!props.config?.fullscreenTarget) {
      if (!props.config) props.config = {};
      props.config.fullscreenTarget = props.id;
    }
    super({
      ...props.config,
      parent: props.id,
    });

    this.hasApi = props.hasApi ?? false;

    this.info = props.info ?? {
      name: "Game",
      description: "A game",
      thumbnail: "/games/thumbnail.jpg",
      key: "game",
    };

    this.ui = props.ui ?? new Signal();
    if (props.bootScene) {
      this.scene.add("boot", props.bootScene, true, {
        baseURL: props.baseURL,
      });
    }

    if (props.scenes) {
      Object.entries(props.scenes).forEach(([key, value]) => {
        this.scene.add(key, value);
      });
    }

    this.events.on("booted", () => {
      const bootScene = this.scene.getScene("boot");
      this.bootScene = bootScene;

      if (!bootScene) return;

      const url = new URL(globalThis.location.href);
      const scene = url.searchParams.get("scene");

      if (scene) {
        this.scene.stop(bootScene);
        this.scene.start(scene);
        return;
      }
    }, this);
  }

  quit() {
    if (this.bootScene?.scale.isFullscreen) {
      this.bootScene.scale.stopFullscreen();
    }

    window.location.href = `/`;
  }
}
