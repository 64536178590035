import { Signal, useSignal } from "@preact/signals";
import { useEffect } from "preact/hooks";
import { BaseGame } from "~/games/deps.ts";
import { isPaused } from "~/games/eikoo/signals.ts";

export const countdownTimerClasses = {
  wrapper:
    `absolute top-0 text-4xl flex justify-center leading-none items-end w-full`,
  ms: `text-gray-400 text-sm`,
} as const;

export default function CountdownTimer({
  game,
  duration = 2 * 60 * 1000,
  elapsed = 0,
  onEnd = () => {
    if (!game) return;

    // TODO: find better way to get active scene....
    if ("onTimerEnd" in game.value.scene.getScenes()[0]) {
      (game.value.scene.getScenes()[0] as unknown as { onTimerEnd: () => void })
        .onTimerEnd?.();
    }
  },
}: {
  game?: Signal<BaseGame>;
  duration?: number;
  elapsed?: number;
  onEnd?: () => void;
}) {
  const now = game?.value.getTime() ?? 0;
  const start = useSignal(!isPaused.value ? now : 0);
  const remaining = useSignal(duration - elapsed);
  const pausedAt = useSignal(0);
  const pausedDuration = useSignal(0);

  useEffect(() => {
    if (!game) return;

    if (isPaused.value) {
      if (pausedAt.value === 0) {
        pausedAt.value = game.value.getTime();
      }
      return;
    }

    if (pausedAt.value > 0) {
      pausedDuration.value += game.value.getTime() - pausedAt.value;
      pausedAt.value = 0;
    }

    const tick = () => {
      const now = game.value.getTime() ?? 0;

      remaining.value = duration - elapsed - (now - start.value) +
        pausedDuration.value;
      if (remaining.value <= 0) {
        remaining.value = 0;
        onEnd?.();
        return;
      }

      if (!isPaused.value) {
        requestAnimationFrame(tick);
      }
    };

    tick();
  }, [game, isPaused.value]);

  const hours = Math.floor(remaining.value / 1000 / 60 / 60);
  const minutes = Math.floor(remaining.value / 1000 / 60) % 60;
  const seconds = Math.floor(remaining.value / 1000) % 60;
  const milliseconds = Math.floor(remaining.value) % 1000;

  return (
    <div class={countdownTimerClasses.wrapper}>
      {hours > 0 && <div>{pad(hours)}:</div>}
      <div>{minutes}:</div>
      <div>{pad(seconds)}</div>
      <div class={countdownTimerClasses.ms}>.{pad(milliseconds, 3)}</div>
    </div>
  );
}

function pad(num: number, size = 2) {
  return num.toFixed().padStart(size, "0");
}
