// You can write more code here
import { ArcadeSprite } from "~/games/deps.ts";
/* START OF COMPILED CODE */

export default interface Character {
	body: Phaser.Physics.Arcade.Body;
}

export default class Character extends ArcadeSprite {
	public id: string;

	constructor(
		scene: Phaser.Scene,
		x?: number,
		y?: number,
		texture?: string,
		frame?: number | string,
	) {
		super(scene, x ?? 0, y ?? 0, texture || "!Character01", frame ?? 1);
		this.id = "Character";

		this.setInteractive(
			new Phaser.Geom.Rectangle(36, 24, 24, 48),
			Phaser.Geom.Rectangle.Contains,
		);
		this.setOrigin(0.5, 0.75);
		scene.physics.add.existing(this, false);
		this.body.setOffset(27, 54);
		this.body.setSize(41, 18, false);

		/* START-USER-CTR-CODE */
		// Write your code here.
		/* END-USER-CTR-CODE */
	}

	public speed: number = 200;

	/* START-USER-CODE */

	// Write your code here.

	/* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
