import { possibilities, statsSignals } from "~/games/eikoo/signals.ts";
import { foods } from "~/games/eikoo/data/clickables.ts";

export default function Possibilities() {
  return (
    <div class="flex flex-wrap justify-center p-4 gap-8">
      {possibilities.value && possibilities.value.map((possibility) => {
        const stats = statsSignals[possibility.key as keyof typeof foods];

        return (
          <div
            class="flex flex-col justify-center items-center text-sm"
            title={possibility.name}
          >
            <div class="flex justify-center items-center">
              <div>{possibility.key}</div>
              <div>=</div>
              <div>{possibility.points}</div>
            </div>
            <div class="flex gap-4 justify-between">
              <div>
                {(stats.collected.value /
                  ((stats.missed.value + stats.collected.value) || 1) * 100)
                  .toFixed()}%
              </div>
              <div>
                {stats.collected.value}
                /
                {stats.missed.value + stats.collected.value}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
