import { selectedGame } from "~/games/signals.ts";
import { Games } from "~/games/mod.ts";
import { Button } from "./Button.tsx";

export default function GameSelector({ games }: { games: Games }) {
  return (
    <div class="h-screen w-screen flex flex-col gap-8 justify-center items-center">
      <h1 class="text-4xl">Phaser Games</h1>

      <p>Select a game to play</p>

      <div class="flex flex-col gap-4">
        {Object.entries(games).map(([game, info]) => {
          return (
            <Button
              class=""
              onClick={() => {
                window.location.href = `/play/${game}`;
              }}
            >
              <div class="flex gap-4">
                {info.thumbnail && (
                  <img
                    src={info.thumbnail}
                    alt={``}
                    class="w-24 h-24"
                  />
                )}

                <div class="text-left">
                  <div class="text-4xl">
                    {info.name}
                  </div>
                  <div>
                    {info.description}
                  </div>
                </div>
              </div>
            </Button>
          );
        })}
      </div>
    </div>
  );
}
