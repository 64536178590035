export class BoxesGrid {
  /**
   * @param {Phaser.Scene} scene
   * @param {number} x
   * @param {number} y
   * @param {number} width
   * @param {number} height
   * @param {number} cellWidth
   * @param {number} cellHeight
   */

  constructor(scene, x, y, width, height, cellWidth, cellHeight) {
    this.scene = scene;
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
    this.cellWidth = cellWidth;
    this.cellHeight = cellHeight;

    /**
     * @type {Array<{score: number, color: number, text: Phaser.GameObjects.Text }>}
     */
    this.players = [{ score: 0, color: 0x990000 }, {
      score: 0,
      color: 0x000099,
    }];
    this.currentPlayer = 0;

    this.cols = Math.floor(this.width / this.cellWidth);
    this.rows = Math.floor(this.height / this.cellHeight);

    /**
     * @type {x: number, y: number, direction: 'horizontal' | 'vertical'}
     */
    this.lastCreatedLine = null;

    /**
     * @type {Map<string, {x: number, y: number, direction: 'horizontal' | 'vertical'}>}
     * @description Map of created lines, with the key being a string of the form 'x,y,direction'
     * where x and y are the coordinates of the line and direction is either 'horizontal' or 'vertical'
     */
    this.createdLines = new Map();

    /**
     * @type {Map<string, {player: number}>}
     * @description Map of filled squares, with the key being a string of the form 'x,y'
     * where x and y are the coordinates of the square
     */
    this.filledSquares = new Map();

    // Setup interaction
    this.setupInteraction();

    this.playerGraphics = this.players.map((player) => {
      return this.scene.add.graphics({
        fillStyle: { color: player.color, alpha: 1 },
      });
    });

    this.graphics = this.scene.add.graphics({
      lineStyle: { width: 1, color: 0xfafafa, alpha: 0.4 },
      fillStyle: { color: 0xadadad },
    });
    this.activeGraphics = this.scene.add.graphics({
      lineStyle: { width: 5, color: 0xffff00 },
    });
    this.linesGraphics = this.scene.add.graphics({
      lineStyle: { width: 3, color: 0xdadada },
    });

    this.vertexSize = 7;

    // Draw the grid
    this.drawGrid();

    const w = this.width + this.vertexSize;
    const h = this.height + this.vertexSize;

    this.textures = [
      this.scene.add.image(w, h, "grid"),
      this.scene.add.image(w, h, "filled"),
      this.scene.add.image(w, h, "linesGraphics"),
      this.scene.add.image(w, h, "activeGraphics"),
    ];

    this.penaltyButton = this.scene.add.text(w / 2, 10, "End Turn", {
      fontFamily: "Arial",
      fontSize: 48,
      color: "#ffff00",
      stroke: "#000000",
      strokeThickness: 6,
    })
      .setOrigin(0, 0);
    this.penaltyButton.visible = false;
    this.penaltyButton.setInteractive().on("pointerdown", () => {
      if (this.penaltyPlay) {
        this.penaltyPlay = false;
        this.currentPlayer = (this.currentPlayer + 1) % this.players.length;
        this.penaltyButton.visible = false;
      }
    });

    this.textures.forEach((texture) => {
      texture.setOrigin(0, 0);
      texture.setPosition(this.x, this.y);
    });
  }

  drawGrid() {
    this.graphics.clear();
    this.activeGraphics.clear();
    this.linesGraphics.clear();

    const vertexSize = this.vertexSize;

    const offset = 0.01;
    const offsetX = vertexSize / 2 + offset;
    const offsetY = vertexSize / 2 + offset;

    const width = this.cols * this.cellWidth + vertexSize;
    const height = this.rows * this.cellHeight + vertexSize;

    for (let i = 0; i <= this.cols; i++) {
      for (let j = 0; j <= this.rows; j++) {
        if (this.filledSquares.has(`${i},${j}`)) {
          const square = this.filledSquares.get(`${i},${j}`);
          this.playerGraphics[square.player].fillRect(
            i * this.cellWidth + offsetX,
            j * this.cellHeight + offsetY,
            this.cellWidth,
            this.cellHeight,
          );
        }

        const matchesLastCreatedCoords = this.lastCreatedLine &&
          this.lastCreatedLine.x === i && this.lastCreatedLine.y === j;
        const isHorizontalLineCreated = this.createdLines.has(
          `${i},${j},horizontal`,
        );
        const isVerticalLineCreated = this.createdLines.has(
          `${i},${j},vertical`,
        );
        // Draw lines
        if (i < this.cols) {
          let lineGraphics = this.graphics;
          if (
            matchesLastCreatedCoords &&
            this.lastCreatedLine.direction === "horizontal"
          ) {
            lineGraphics = this.activeGraphics;
          } else if (isHorizontalLineCreated) {
            lineGraphics = this.linesGraphics;
          }

          lineGraphics.lineBetween(
            i * this.cellWidth + offsetX,
            j * this.cellHeight + offsetY,
            (i + 1) * this.cellWidth + offsetX,
            j * this.cellHeight + offsetY,
          );
        }
        if (j < this.rows) {
          let lineGraphics = this.graphics;
          if (
            matchesLastCreatedCoords &&
            this.lastCreatedLine.direction === "vertical"
          ) {
            lineGraphics = this.activeGraphics;
          } else if (isVerticalLineCreated) {
            lineGraphics = this.linesGraphics;
          }

          lineGraphics.lineBetween(
            i * this.cellWidth + offsetX,
            j * this.cellHeight + offsetY,
            i * this.cellWidth + offsetX,
            (j + 1) * this.cellHeight + offsetY,
          );
        }

        this.graphics.fillRect(
          i * this.cellWidth + offsetX - vertexSize / 2,
          j * this.cellHeight + offsetY - vertexSize / 2,
          vertexSize,
          vertexSize,
        );
      }
    }

    // clear textures
    if (this.scene.textures.exists("grid")) {
      this.scene.textures.get("grid")?.clear();
    }
    if (this.scene.textures.exists("linesGraphics")) {
      this.scene.textures.get("linesGraphics")?.clear();
    }
    if (this.scene.textures.exists(`filled`)) {
      this.scene.textures.get(`filled`)?.clear();
    }
    if (this.scene.textures.exists(`activeGraphics`)) {
      this.scene.textures.get(`activeGraphics`)?.clear();
    }

    this.graphics.generateTexture("grid", width, height);
    this.linesGraphics.generateTexture("linesGraphics", width, height);
    this.activeGraphics.generateTexture("activeGraphics", width, height);

    this.playerGraphics.forEach((graphics) => {
      graphics.generateTexture(`filled`, width, height);
      graphics.clear();
    });

    this.graphics.clear();
    this.linesGraphics.clear();
    this.activeGraphics.clear();
  }

  setupInteraction() {
    this.scene.input.on("pointerdown", (pointer) => {
      // Adjust pointer coordinates for grid offset
      const adjustedX = pointer.x - this.x - this.vertexSize / 2;
      const adjustedY = pointer.y - this.y - this.vertexSize / 2;

      // Calculate distances to the nearest vertical and horizontal lines
      const distToVertLine = adjustedX % this.cellWidth;
      const distToHorizLine = adjustedY % this.cellHeight;

      // Determine the absolute distance to the nearest line in both directions
      const absDistToVertLine = Math.abs(
        Math.min(distToVertLine, this.cellWidth - distToVertLine),
      );
      const absDistToHorizLine = Math.abs(
        Math.min(distToHorizLine, this.cellHeight - distToHorizLine),
      );

      const interactionDistance = 10;

      // Check if the click is within 10 pixels of any line but not closer to the perpendicular line
      const closeToVertLine = absDistToVertLine <= interactionDistance &&
        absDistToVertLine < absDistToHorizLine;
      const closeToHorizLine = absDistToHorizLine <= interactionDistance &&
        absDistToHorizLine < absDistToVertLine;

      // Ensure the click is not within interactionDistance pixels of a vertex
      const nearVertex = absDistToVertLine <= interactionDistance &&
        absDistToHorizLine <= interactionDistance;

      if (nearVertex) {
        // console.log('Click is near a vertex, ignoring.');
      } else if (closeToVertLine || closeToHorizLine) {
        const lastCreatedLine = {
          x: Math.floor((adjustedX + interactionDistance) / this.cellWidth),
          y: Math.floor((adjustedY + interactionDistance) / this.cellHeight),
          direction: closeToVertLine ? "vertical" : "horizontal",
        };

        if (
          lastCreatedLine.x < 0 || lastCreatedLine.y < 0 ||
          lastCreatedLine.x > this.cols || lastCreatedLine.y > this.rows ||
          (lastCreatedLine.direction === "vertical" &&
            lastCreatedLine.y === this.rows) ||
          (lastCreatedLine.direction === "horizontal" &&
            lastCreatedLine.x === this.cols)
        ) {
          return;
        }

        const key =
          `${lastCreatedLine.x},${lastCreatedLine.y},${lastCreatedLine.direction}`;
        if (this.createdLines.has(key)) {
          return;
        }

        this.lastCreatedLine = lastCreatedLine;
        this.createdLines.set(key, this.lastCreatedLine);
        this.fillCreatedSquares();
        this.drawGrid();
        // console.log('Click is near a line.', this.lastCreatedLine, rows, cols);
      } else {
        // console.log('Click is not near any line or vertex.');
      }
    });
  }

  checkForSquare(lineToCheck, existingLines = this.createdLines) {
    if (existingLines.size < 4) {
      return [];
    }
    // use the last created line to check for a square
    const { x, y, direction } = lineToCheck;

    const createdSquares = [];

    // if the last created line is horizontal, check for a square above and below it
    if (direction === "horizontal") {
      // check for a square above
      if (
        existingLines.has(`${x},${y - 1},vertical`) &&
        existingLines.has(`${x + 1},${y - 1},vertical`) &&
        existingLines.has(`${x},${y - 1},horizontal`)
      ) {
        if (!this.filledSquares.has(`${x},${y - 1}`)) {
          createdSquares.push(`${x},${y - 1}`);
        }
      }
      // check for a square below
      if (
        existingLines.has(`${x},${y},vertical`) &&
        existingLines.has(`${x + 1},${y},vertical`) &&
        existingLines.has(`${x},${y + 1},horizontal`)
      ) {
        if (!this.filledSquares.has(`${x},${y}`)) {
          createdSquares.push(`${x},${y}`);
        }
      }
    }

    // if the last created line is vertical, check for a square to the left and right of it
    if (direction === "vertical") {
      // check for a square to the left
      if (
        existingLines.has(`${x - 1},${y},horizontal`) &&
        existingLines.has(`${x - 1},${y + 1},horizontal`) &&
        existingLines.has(`${x - 1},${y},vertical`)
      ) {
        if (!this.filledSquares.has(`${x - 1},${y}`)) {
          createdSquares.push(`${x - 1},${y}`);
        }
      }
      // check for a square to the right
      if (
        existingLines.has(`${x},${y},horizontal`) &&
        existingLines.has(`${x},${y + 1},horizontal`) &&
        existingLines.has(`${x + 1},${y},vertical`)
      ) {
        if (!this.filledSquares.has(`${x},${y}`)) {
          createdSquares.push(`${x},${y}`);
        }
      }
    }

    return createdSquares;
  }

  fillCreatedSquares() {
    const createdSquares = this.checkForSquare(this.lastCreatedLine);

    if (createdSquares.length > 0) {
      createdSquares.forEach((square) => {
        this.filledSquares.set(square, { player: this.currentPlayer });
      });
      this.players[this.currentPlayer].score += createdSquares.length;

      this.checkForWin();
    } else {
      if (this.penaltyPlay) {
        this.penaltyButton.visible = false;
        this.penaltyPlay = false;
      }

      // penalize the player if they left a square for the other player
      if (this.checkForPenalty()) {
        this.penaltyPlay = true;
        this.penaltyButton.visible = true;
      }

      this.currentPlayer = (this.currentPlayer + 1) % this.players.length;
    }
  }

  checkForPenalty() {
    // don't include the last placed line in the penalty check
    // allowing a square with your last move is not a penalty
    // leaving any other squares for the other player is a penalty
    const existingLines = new Map(this.createdLines);
    const lastCreatedKey =
      `${this.lastCreatedLine.x},${this.lastCreatedLine.y},${this.lastCreatedLine.direction}`;
    existingLines.delete(lastCreatedKey);

    // check all empty lines, see if placing a line there would create a square
    for (let i = 0; i < this.cols; i++) {
      for (let j = 0; j < this.rows; j++) {
        if (!this.filledSquares.has(`${i},${j}`)) {
          const hasPenalty = ["horizontal", "vertical"].some((direction) => {
            // if this line doesn't exist, check if it would create a square
            if (!this.createdLines.has(`${i},${j},${direction}`)) {
              // if it would create a square, player has a penalty
              if (
                this.checkForSquare({ x: i, y: j, direction }, existingLines)
                  .length
              ) {
                return true;
              }
            }
          });

          if (hasPenalty) {
            return true;
          }
        }
      }
    }

    return false;
  }

  checkForWin() {
    const totalSquares = Math.floor(this.width / this.cellWidth) *
      Math.floor(this.height / this.cellHeight);
    if (this.filledSquares.size === totalSquares) {
      // this.scene.scene.start("GameOver", {
      //   winner: this.players.reduce((acc, player, i) => {
      //     if (player.score > this.players[acc].score) {
      //       return i;
      //     }
      //     return acc;
      //   }, null),
      //   players: this.players,
      // });
    }
  }
}
