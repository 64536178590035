import { Scene } from "~/games/deps.ts";
import { ui } from "~/games/signals.ts";
import CountdownTimer from "~/games/eikoo/components/CountdownTimer.tsx";
import { Card, faces, suits } from "~/games/hold-em/components/Card.ts";
import SocketStatus from "~/games/components/hud/SocketStatus.tsx";
import { MusicPlayer } from "~/games/components/player/MusicPlayer.tsx";

export class GameScene extends Scene {
    cards: Card[] = [];

    create() {
        ui.value = [
            CountdownTimer,
            SocketStatus,
            MusicPlayer,
        ];

        suits.forEach((suit, r) => {
            faces.forEach((face, i) => {
                const card = new Card(
                    this,
                    100 + i * 100,
                    150 * (r + 1),
                    suit,
                    face,
                );
                this.cards.push(card);
                this.add.existing(card);
            });
        });
    }
    override update() {
    }
}
