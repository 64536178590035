import { Scene } from "~/games/deps.ts";
import { Button } from "~/games/components/button.ts";
import { isGameOver, points, possibilities, statsSignals } from "../signals.ts";
import Points from "../components/Points.tsx";
import { ui } from "~/games/signals.ts";
import { foods } from "../data/clickables.ts";
import Possibilities from "~/games/eikoo/components/Possibilities.tsx";
import { ObjectWithRate } from "~/games/eikoo/types.ts";
import CountdownTimer from "~/games/eikoo/components/CountdownTimer.tsx";
import GameMenu from "~/games/eikoo/components/GameMenu.tsx";
import { useSignal } from "@preact/signals";

export default class GameScene extends Scene {
  clickables: {
    button: Phaser.GameObjects.Text;
    speed: Phaser.Math.Vector2;
  }[] = [];
  possibilities: ObjectWithRate[] = [];
  screenRect: Phaser.Geom.Rectangle = new Phaser.Geom.Rectangle();

  getWeightedList(maxPoints: number, minPoints = 0) {
    const possibleItems = Object.entries(foods).filter(([_key, food]) =>
      food.points <= maxPoints && food.points >= minPoints
    ).map(([key, food]) => {
      return {
        key,
        ...food,
        normalizedRate: 0,
      };
    }); // filter out foods that are too expensive

    const totalRate = possibleItems.reduce((sum, obj) => sum + obj.rate, 0);
    possibleItems.forEach((obj) => {
      obj.normalizedRate = obj.rate / totalRate;
    });

    return possibleItems as ObjectWithRate[];
  }
  weightedRandomChoice(objects: ObjectWithRate[]): ObjectWithRate {
    let cumulative = 0;
    const choice = Math.random();
    for (const obj of objects) {
      if (obj.normalizedRate) {
        cumulative += obj.normalizedRate;
        if (choice <= cumulative) {
          return obj;
        }
      }
    }
    throw new Error("No object selected");
  }
  spawnClickable(
    clickableButton: Phaser.GameObjects.Text,
    rect: Phaser.Geom.Rectangle,
    possibilities: ObjectWithRate[],
  ) {
    const clickable = this.weightedRandomChoice(possibilities);
    clickableButton.setData("points", clickable.points);
    clickableButton.setData("key", clickable.key);
    clickableButton.setText(clickable.key);
    const randomPoint = rect.getRandomPoint();
    clickableButton.setPosition(randomPoint.x, randomPoint.y - rect.bottom);
  }
  onTimerEnd() {
    console.log("timer ended");
    this.scene.start("game-over");
  }
  create() {
    isGameOver.value = false;
    this.possibilities = this.getWeightedList(points.value + 500);
    possibilities.value = this.possibilities;

    this.clickables = [];

    Array.from({ length: 10 }).map(() => {
      requestIdleCallback(() => {
        const clickable = this.weightedRandomChoice(this.possibilities);
        this.screenRect = new Phaser.Geom.Rectangle(
          0,
          0,
          this.cameras.main.width,
          this.cameras.main.height,
        );
        const randomPoint = this.screenRect.getRandomPoint();
        const clickableButton = Button(
          this,
          clickable.key,
          randomPoint.x,
          randomPoint.y - this.screenRect.bottom,
          () => {
            points.value += clickableButton.data.get("points");
            statsSignals[
              clickableButton.data.get("key") as keyof typeof statsSignals
            ].collected
              .value += 1;
            this.spawnClickable(
              clickableButton,
              this.screenRect,
              this.possibilities,
            );
          },
        );
        clickableButton.setData("points", clickable.points);
        clickableButton.setData("key", clickable.key);
        clickableButton.setFontSize(40);
        clickableButton.setOrigin(.5, .5);
        this.clickables.push({
          button: clickableButton,
          speed: new Phaser.Math.Vector2(
            Math.random() * 100 - 50,
            Math.random() * 100 + 100,
          ),
        });
      });
    });

    ui.value = [
      Points,
      Possibilities,
      CountdownTimer,
      GameMenu,
    ];
  }

  update(_time: number, delta: number): void {
    this.clickables.forEach((clickable) => {
      clickable.button.setPosition(
        clickable.button.x + clickable.speed.x * delta / 1000,
        clickable.button.y + clickable.speed.y * delta / 1000,
      );

      if (clickable.button.x < 0) {
        clickable.speed.x = Math.abs(clickable.speed.x);
      }
      if (clickable.button.x > this.cameras.main.width) {
        clickable.speed.x = -Math.abs(clickable.speed.x);
      }

      if (clickable.button.y > this.cameras.main.height + 50) {
        points.value -= clickable.button.getData("points");
        statsSignals[
          clickable.button.getData("key") as keyof typeof statsSignals
        ].missed
          .value += 1;

        this.spawnClickable(
          clickable.button,
          this.screenRect,
          this.possibilities,
        );
      }
    });
  }
}
