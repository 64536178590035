import { BaseGame } from "../deps.ts";
import { config } from "./config.ts";
import BootScene from "./scenes/Boot.ts";
import { ui } from "~/games/signals.ts";
import { GameScene } from "./scenes/GameScene.ts";
import { LobbyScene } from "./scenes/LobbyScene.ts";

export default class Game extends BaseGame {
    constructor(props: { id: string; baseURL?: string }) {
        const { class: _gameClass, ...info } = GameInfo;
        super({
            ...props,
            id: props.id,
            baseURL: props.baseURL,
            config,
            bootScene: BootScene,
            ui,
            scenes: {
                game: GameScene,
                lobby: LobbyScene,
            },
            hasApi: true,
            info,
        });
    }
}

export const GameInfo = {
    class: Game,
    name: "Texas Hold'em",
    key: "hold-em",
    description: "Poker",
    thumbnail: "/games/thumbnail.jpg",
};
