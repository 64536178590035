import { Signal, useSignal } from "@preact/signals";
import { useEffect, useLayoutEffect, useRef, useState } from "preact/hooks";
import { useColorSort } from "./useColorSort.ts";
import { BaseGame } from "~/games/deps.ts";

type ImageInfo = { key: string; src: string; elm: HTMLImageElement };
export const ImageList = (
    props: { scene: Phaser.Scene; images: ImageInfo[] },
) => {
    const editImage = useSignal<ImageInfo | null>(null);
    const imageList = useSignal(props.images);
    const images = imageList.value;
    const [selectedItems, setSelectedItems] = useState<string[]>([]);

    const lastSelected = useRef<string | null>(null);
    const [width, setWidth] = useState(300);

    const classes = {
        button: "px-4 py-1 bg-zinc-800 hover:bg-zinc-900",
    };

    // prevent pointer events from propagating to the parent makes sure that events are captured by the ui element and not passed to the game
    useLayoutEffect(() => {
        // const elm = document.querySelector(".flex.flex-col");
        // const eventHandler = (e: Event) => {
        //     e.stopPropagation();
        //     e.preventDefault();
        //     e.stopImmediatePropagation();
        // };

        // if (elm) {
        //     elm.addEventListener("pointerdown", eventHandler);
        // }

        const canvas = props.scene.game.canvas;
        // resize the canvas to fit the remaining space (minus the width of the ui)
        canvas.style.width = `calc(100% - ${width}px)`;
        canvas.style.height = "100%";
        props.scene.scale.resize(canvas.clientWidth, canvas.clientHeight);

        canvas.style.position = "absolute";
        canvas.style.left = `${width}px`;
        // tell the game to resize the canvas

        // return () => {
        //     elm?.removeEventListener("pointerdown", eventHandler);
        // };
    });

    const lastResize = useRef(0);

    return (
        <div
            class="ui flex flex-col h-screen bg-slate-800 relative"
            style={{ width: `${width}px` }}
        >
            <div
                class="absolute top-0 right-[-8px] bottom-0 border-4 hover:border-slate-950 border-opacity-70 border-transparent cursor-col-resize"
                onDrag={(e) => {
                    // how to skip processing if drag end
                    if (e.pageX === 0) return;

                    if (performance.now() - lastResize.current > 100) {
                        lastResize.current = performance.now();
                        if (e.pageX > 57) setWidth(e.pageX);
                        else setWidth(57);
                    }
                }}
                onDragEnd={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
                draggable
            >
            </div>
            <div class="flex justify-start items-stretch text-base bg-slate-700 border-b border-slate-900">
                <button
                    class={classes.button}
                    onClick={() => {
                        // sort by key
                        imageList.value = [...images].sort((a, b) =>
                            a.key.localeCompare(b.key)
                        );
                    }}
                >
                    Key
                </button>
                <button
                    class={classes.button}
                    onClick={() => {
                        const sorted = useColorSort(images);
                        imageList.value = sorted;
                        console.log("done sorting");
                    }}
                >
                    Color
                </button>
                <button
                    class={classes.button}
                    onClick={() => {
                        imageList.value = [...images].reverse();
                    }}
                >
                    Reverse
                </button>
            </div>
            <div class="flex flex-wrap items-start justify-start overflow-auto">
                {images.map((image, index) => {
                    const ref = useRef<HTMLImageElement>(null);
                    const selected = selectedItems.includes(image.key);

                    useEffect(() => {
                        if (ref.current) {
                            ref.current.replaceWith(
                                image.elm,
                            );
                        }
                    }, [image]);

                    return (
                        <button
                            key={image.key}
                            class={`relative group hover:bg-slate-900 p-2 text-center ${
                                selected ? "bg-zinc-950" : ""
                            }`}
                            draggable
                            // make droppable
                            onDragOver={(e) => {
                                e.preventDefault();
                                e.dataTransfer!.dropEffect = "move";
                            }}
                            onDblClick={() => {
                                editImage.value = image;
                                console.log(
                                    "sending to game",
                                    props.scene,
                                );
                                props.scene.events.emit("edit-image", image);
                            }}
                            onDrop={(e) => {
                                const key = e.dataTransfer?.getData(
                                    "text/plain",
                                );
                                const s = imageList.value.findIndex(
                                    (img) => img.key === key,
                                );
                                const t = imageList.value.findIndex(
                                    (img) => img.key === image.key,
                                );
                                const newImages = [...imageList.value];
                                newImages.splice(s, 1);
                                newImages.splice(t, 0, imageList.value[s]);
                                imageList.value = newImages;
                            }}
                            onDragStart={(e) => {
                                e.dataTransfer?.setData(
                                    "text/plain",
                                    image.key,
                                );
                            }}
                            onClick={(e) => {
                                const keepItems = [];
                                if (e.ctrlKey) {
                                    keepItems.push(...selectedItems);
                                }

                                if (e.shiftKey && lastSelected.current) {
                                    const start = images.findIndex((img) =>
                                        img.key === lastSelected.current
                                    );
                                    const end = images.findIndex((img) =>
                                        img.key === image.key
                                    );
                                    const range = images.slice(
                                        Math.min(start, end),
                                        Math.max(start, end) + 1,
                                    );
                                    setSelectedItems([
                                        ...keepItems,
                                        ...range.map((img) => img.key),
                                    ]);
                                } else {
                                    // if more than one selected, check if the current image is already selected
                                    // if so, clear the list and add the current image
                                    // otherwise, clear the list and add the current image
                                    if (
                                        selectedItems.length > 1 &&
                                        keepItems.length === 0
                                    ) {
                                        if (selectedItems.includes(image.key)) {
                                            setSelectedItems([image.key]);
                                        } else {
                                            setSelectedItems([image.key]);
                                        }
                                    } else {
                                        setSelectedItems([
                                            ...keepItems,
                                            image.key,
                                        ]);
                                    }

                                    lastSelected.current = image.key;
                                }
                            }}
                        >
                            <img ref={ref} class="m-0" />
                            <span class="text-sm absolute bottom-0 px-2 bg-zinc-800 bg-opacity-60 rounded hidden group-hover:hidden">
                                {image.key}
                            </span>
                        </button>
                    );
                })}
            </div>
        </div>
    );
};
