import { Signal } from "@preact/signals";
import Orb from "~/games/components/hud/Orb.tsx";

export default function Hud({
    life = new Signal(100),
    mana = new Signal(50),
}) {
    return (
        <div class="absolute bottom-0 w-full">
            <div class="flex justify-between max-w-screen-2xl mx-auto w-full">
                <Orb type="life" value={life.value} />

                <Orb type="mana" value={mana.value} />
            </div>
        </div>
    );
}
